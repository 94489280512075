import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperRentalHousePage from '../component/WrapperRentalHousePage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  rentalHouses: state.rentalHouses,
});

const mapDispatchToProps = dispatch => ({
  getRentalHouses: () => dispatch(Actions.getRentalHouses()),
  getRentalHouseById: (...args) => dispatch(Actions.getRentalHouseById(...args)),
  createRentalHouse: (...args) => dispatch(Actions.createRentalHouse(...args)),
  deleteRentalHouse: (...args) => dispatch(Actions.deleteRentalHouse(...args)),
  updateRentalHouse: (...args) => dispatch(Actions.updateRentalHouse(...args)),
});
class RentalHousePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    rentalHouses: PropTypes.array,
    getRentalHouses: PropTypes.func,
    getRentalHouseById: PropTypes.func,
    createRentalHouse: PropTypes.func,
    deleteRentalHouse: PropTypes.func,
    updateRentalHouse: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, rentalHouses } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: rentalHouses.length === 0,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getRentalHouses } = this.props;
    await Promise.all([
      getRentalHouses(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      rentalHouses,
      getRentalHouseById,
      createRentalHouse,
      deleteRentalHouse,
      updateRentalHouse,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperRentalHousePage
        refresh={this.refresh.bind(this)}
        loading={loading}
        rentalHouses={rentalHouses}
        getRentalHouseById={getRentalHouseById}
        createRentalHouse={createRentalHouse}
        deleteRentalHouse={deleteRentalHouse}
        updateRentalHouse={updateRentalHouse}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RentalHousePage);
