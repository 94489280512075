import {
  GET_SERVICE_NAMES,
} from 'constants/serviceNames';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SERVICE_NAMES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
