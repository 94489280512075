import AppBar from "@material-ui/core/AppBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import BrightnessHigh from "@material-ui/icons/BrightnessHigh";
import BrightnessLow from "@material-ui/icons/BrightnessLow";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import App from "components/App";
// reactor
import Apps from "components/Apps";
import AuthChecker from "components/AuthChecker";
import ForgotPassword from "components/ForgotPassword";
import KeyboardEvents from "components/KeyboardEvents";
import NotificationCenter from "components/NotificationCenter";
import ResetPassword from "components/ResetPassword";
import Signup from "components/Signup";
import Upload from "components/Upload";
import UrlActions from "components/UrlActions";
import WebSocket from "components/WebSocket";
import {
  GOOGLE_ANALYTICS_KEY,
  REACT_APP_FRONT_BASE,
  REACT_APP_LOGO,
} from "config";
import { menu } from "config/menu";
import { DEFAULT, MY_ACCOUNT, SIGNIN } from "constants/routes";
import { redirectAccordingToRole } from "helpers/redirect";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// styles
import styles from "./styles";

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    allRoutes: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,
    theme: PropTypes.object,

    // Reducers
    user: PropTypes.object,
    userApps: PropTypes.object,

    // Api
    getCurrentUser: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    stopImpersonate: PropTypes.func,

    // errors
    errors: PropTypes.object,

    // app
    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    cookies: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      mobileOpen: false,
      anchorEl: null,
      miniActive: true,
      accountOpen: false,
      loading: true,
      alert: null,
    };

    if (GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      if (GOOGLE_ANALYTICS_KEY) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  getMenu() {
    const { routes, history, location, classes } = this.props;

    const route = routes.find((r) => r.path === location.pathname);

    const JSX = [];
    for (const k in menu) {
      if (menu.hasOwnProperty(k)) {
        const menuItem = menu[k];
        const Icon = menuItem.icon;

        const currentRoute = routes.find((e) => e.path === menuItem.path);

        if (currentRoute && currentRoute.onEnter && currentRoute.onEnter()) {
          let selected = false;
          if (menuItem.path && menuItem.path === route.path) {
            selected = true;
          }

          JSX.push(
            <ListItem
              selected={selected && menuItem.nested === undefined}
              key={menuItem.path}
              button
              divider={menuItem.divider || Number(k) === menu.length - 1}
              onClick={() => {
                if (menuItem.path) {
                  history.push(menuItem.path);
                  if (menuItem.nested) {
                    if (this.state[menuItem.label] === undefined) {
                      this.setState({ [menuItem.label]: true });
                    } else {
                      this.setState((prevState) => ({
                        [menuItem.label]: !prevState[menuItem.label],
                      }));
                    }
                  }
                }
              }}
            >
              <ListItemIcon>
                <Icon
                  className={
                    selected && menuItem.nested === undefined
                      ? classes.selected
                      : undefined
                  }
                />
              </ListItemIcon>
              <ListItemText
                inset
                classes={{
                  primary:
                    selected && menuItem.nested === undefined
                      ? classes.selected
                      : undefined,
                }}
                primary={menuItem.label}
              />
              {menuItem.nested && (
                <div>
                  {this.state[menuItem.label] ? <ExpandLess /> : <ExpandMore />}
                </div>
              )}
            </ListItem>
          );

          if (menuItem.nested) {
            const nestedMenu = [];
            for (const m in menuItem.nested) {
              if (menuItem.nested.hasOwnProperty(m)) {
                const n = menuItem.nested[m];
                const NestedIcon = n.icon;
                if (n.path && n.path === route.path) {
                  selected = true;
                } else {
                  selected = false;
                }

                nestedMenu.push(
                  <ListItem
                    selected={selected}
                    key={`nested_${n.path}`}
                    button
                    className={classes.nested}
                    divider={n.divider || Number(k) === menu.length - 1}
                    onClick={() => {
                      if (n.path) {
                        history.push(n.path);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <NestedIcon
                        className={
                          selected ? classes.selected : classes.nestedWhite
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      inset
                      classes={{
                        primary: selected
                          ? classes.selected
                          : classes.nestedWhite,
                      }}
                      primary={n.label}
                    />
                  </ListItem>
                );
              }
            }

            JSX.push(
              <Collapse
                key={`collapse_${menuItem.path}`}
                in={
                  this.state[menuItem.label] !== undefined
                    ? this.state[menuItem.label]
                    : menuItem.nested.find((n) => n.path === route.path) !==
                      undefined
                }
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.background}
                >
                  {nestedMenu}
                </List>
              </Collapse>
            );
          }
        }
      }
    }

    return (
      <div>
        <div className={classes.toolbar} />
        <List className={classes.list} component="nav">
          {JSX}
          {/* {
            user.services
              ? (
                <ListItem
                  key={'signout'}
                  button
                  divider
                  onClick={async () => {
                    await signout();
                    history.push(SIGNIN);
                  }}
                >
                  <ListItemIcon>
                    <PowerOff />
                  </ListItemIcon>
                  <ListItemText inset primary={'Signout'} />
                </ListItem>
              )
              : (
                <ListItem
                  key={'signin'}
                  button
                  divider
                  onClick={() => {
                    history.push(SIGNIN);
                  }}
                >
                  <ListItemIcon>
                    <Fingerprint />
                  </ListItemIcon>
                  <ListItemText inset primary={'Signin'} />
                </ListItem>
              )
          } */}
        </List>
      </div>
    );
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  handleAppBarMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAppBarClose = () => {
    this.setState({ anchorEl: null });
  };

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div>
        <Switch>
          {routes.map(
            (route) =>
              route.path === DEFAULT ? (
                <Route key={route.path} component={route.component} />
              ) : (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              )
          )}
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      signout,
      storeResetPasswordToken,
      app,
      resetPassword,
      user,
      theme,
      setDarkMode,
      cookies,
      userApps,
      allRoutes,
      init,
      stopImpersonate,
    } = this.props;

    const { loading, alert, anchorEl } = this.state;

    const accountOpen = Boolean(anchorEl);

    const route = routes.find((r) => r.path === location.pathname);

    console.log(user.impersonate);

    return (
      <div>
        {/* {
          !REACT_APP_PROD
            ? (
              <TopMessage
                icon={<Warning style={{ color: '#FFFFFF' }} />}
                color={'#ffffff'}
                background={'#263238'}
                subtitle={'Warning'}
                body={`You are using the beta version.
                  This version contains experimental features and is using a fake API.`
                }
                action={'Ok'}
                position="bottom"
              />
            )
            : (
              <TopMessage
                icon={<Warning style={{ color: '#FFFFFF' }} />}
                color={'#ffffff'}
                background={'rgba(0,0,0,0.8)'}
                subtitle={'Cookie Info'}
                body={'This website uses cookies for functional and analytical purposes. '}
                action={'I Accept'}
                position="top"
                cookie="cookieAccepted"
              />
            )
        } */}
        <AuthChecker
          routes={allRoutes}
          history={history}
          location={location}
          user={user}
          getCurrentUser={getCurrentUser}
          onReady={() => {
            this.setState({ loading: false });
          }}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          init={init}
        >
          <NotificationCenter errors={errors}>
            <WebSocket user={user}>
              <KeyboardEvents>
                <UrlActions
                  location={location}
                  history={history}
                  validateEmail={validateEmail}
                  storeResetPasswordToken={storeResetPasswordToken}
                >
                  <Signup
                    history={history}
                    signup={signup}
                    validateEmail={validateEmail}
                  >
                    <ForgotPassword
                      history={history}
                      requestResetPassword={requestResetPassword}
                    >
                      <ResetPassword
                        token={app.resetPasswordToken}
                        resetPassword={resetPassword}
                        storeResetPasswordToken={storeResetPasswordToken}
                      >
                        <Upload>
                          {loading ? (
                            <Grid
                              container
                              className={classes.root}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <CircularProgress
                                  style={{ color: "#ffffff" }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <div className={classes.root}>
                              {route &&
                                route.withSidebar && (
                                  <div>
                                    <Hidden mdUp>
                                      <Drawer
                                        variant="temporary"
                                        anchor={
                                          theme.direction === "rtl"
                                            ? "right"
                                            : "left"
                                        }
                                        open={this.state.mobileOpen}
                                        onClose={this.handleDrawerToggle}
                                        classes={{
                                          paper: classes.drawerPaper,
                                        }}
                                        ModalProps={{
                                          keepMounted: true,
                                        }}
                                      >
                                        <div
                                          className={classes.drawerContainer}
                                        >
                                          {this.getMenu()}
                                        </div>
                                      </Drawer>
                                    </Hidden>
                                    <Hidden smDown implementation="css">
                                      <Drawer
                                        // onMouseEnter={() => {
                                        //   this.setState({ miniActive: true });
                                        // }}
                                        // onMouseLeave={() => {
                                        //   this.setState({ miniActive: false });
                                        // }}
                                        variant="permanent"
                                        open={!this.state.miniActive}
                                        classes={{
                                          paper: classNames(
                                            classes.drawerPaper,
                                            !this.state.miniActive &&
                                              classes.drawerPaperClose
                                          ),
                                        }}
                                      >
                                        <div
                                          className={classes.drawerContainer}
                                        >
                                          {this.getMenu()}
                                        </div>
                                      </Drawer>
                                    </Hidden>
                                  </div>
                                )}
                              <main className={classes.content}>
                                {route &&
                                  route.withAppBar && (
                                    <AppBar position="static" color="secondary">
                                      <Toolbar variant="dense">
                                        <Hidden mdUp implementation="css">
                                          <IconButton
                                            className={classes.menuButton}
                                            color="inherit"
                                            aria-label="Menu"
                                            onClick={() => {
                                              this.setState((prevState) => ({
                                                mobileOpen: !prevState.mobileOpen,
                                              }));
                                            }}
                                          >
                                            <MenuIcon />
                                          </IconButton>
                                        </Hidden>
                                        <div className={classes.flex}>
                                          <Grid
                                            container
                                            spacing={8}
                                            alignItems="center"
                                          >
                                            <Grid item>
                                              <img
                                                alt="logo"
                                                src={REACT_APP_LOGO}
                                                height="40"
                                              />
                                            </Grid>
                                            <Grid item>
                                              <App
                                                fullName
                                                color={
                                                  theme.palette.primary
                                                    .color[500]
                                                }
                                                name={"Predilux"}
                                                size={30}
                                              />
                                            </Grid>
                                          </Grid>
                                          {/* <Typography variant="caption" color="inherit">
                                                  {`V${REACT_APP_CI_COMMIT_SHA}
                                                  ${REACT_APP_CI_COMMIT_TAG}`}
                                                </Typography> */}
                                        </div>
                                        {user && (
                                          <div>
                                            <Grid container alignItems="center">
                                              <Hidden
                                                smDown
                                                implementation="css"
                                              >
                                                <Grid item>
                                                  <Apps
                                                    userApps={userApps}
                                                    app={app}
                                                    history={history}
                                                    baseHostname={
                                                      REACT_APP_FRONT_BASE
                                                    }
                                                  />
                                                </Grid>
                                              </Hidden>
                                              <Hidden
                                                smDown
                                                implementation="css"
                                              >
                                                <Grid item>
                                                  <Tooltip
                                                    title={
                                                      app.darkMode
                                                        ? "Set Light Mode"
                                                        : "Set Dark Mode"
                                                    }
                                                  >
                                                    <IconButton
                                                      aria-label="DarkMode"
                                                      onClick={() => {
                                                        setDarkMode(
                                                          !app.darkMode
                                                        );
                                                        cookies.set(
                                                          "darkMode",
                                                          !app.darkMode,
                                                          { path: "/" }
                                                        );
                                                      }}
                                                    >
                                                      {app.darkMode ? (
                                                        <BrightnessLow />
                                                      ) : (
                                                        <BrightnessHigh
                                                          style={{
                                                            color: "white",
                                                          }}
                                                        />
                                                      )}
                                                    </IconButton>
                                                  </Tooltip>
                                                </Grid>
                                              </Hidden>
                                              <Hidden
                                                smDown
                                                implementation="css"
                                              >
                                                <Grid item>
                                                  {user && (
                                                    <Typography
                                                      variant="body2"
                                                      className={
                                                        classes.AppBarGreetings
                                                      }
                                                    >
                                                      {`${user.firstName} ${
                                                        user.lastName
                                                      }`}
                                                    </Typography>
                                                  )}
                                                </Grid>
                                              </Hidden>
                                              <Grid item>
                                                <IconButton
                                                  aria-owns={
                                                    accountOpen
                                                      ? "menu-appbar"
                                                      : null
                                                  }
                                                  aria-haspopup="true"
                                                  onClick={
                                                    this.handleAppBarMenu
                                                  }
                                                  color="inherit"
                                                >
                                                  <AccountCircle />
                                                </IconButton>
                                                <Menu
                                                  id="menu-appbar"
                                                  anchorEl={anchorEl}
                                                  anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                  }}
                                                  transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                  }}
                                                  open={accountOpen}
                                                  onClose={
                                                    this.handleAppBarClose
                                                  }
                                                >
                                                  <MenuItem
                                                    onClick={() => {
                                                      this.handleAppBarClose();
                                                      history.push(MY_ACCOUNT);
                                                    }}
                                                  >
                                                    My account
                                                  </MenuItem>
                                                  <MenuItem
                                                    onClick={async () => {
                                                      await signout();
                                                      window.location.replace(
                                                        `${REACT_APP_FRONT_BASE}/signin`
                                                      );
                                                    }}
                                                  >
                                                    Sign out
                                                  </MenuItem>
                                                </Menu>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        )}
                                      </Toolbar>
                                    </AppBar>
                                  )}
                                {this.injectRoutes()}
                                {alert}
                              </main>
                            </div>
                          )}
                        </Upload>
                      </ResetPassword>
                    </ForgotPassword>
                  </Signup>
                </UrlActions>
              </KeyboardEvents>
            </WebSocket>
          </NotificationCenter>
        </AuthChecker>
      </div>
    );
  }
}

export default withCookies(
  withRouter(
    connect()(withStyles(styles, { withTheme: true })(WrapperRootPage))
  )
);
