import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

// component
import WrapperFamiliesPage from '../component/WrapperFamiliesPage';

const mapStateToProps = state => ({
  families: state.families,
  roles: state.roles,
});

const mapDispatchToProps = dispatch => ({
  getFamilies: () => dispatch(Actions.getFamilies()),
  getRoles: () => dispatch(Actions.getRoles()),
  getFamilieById: (...args) => dispatch(Actions.getFamilieById(...args)),
  createFamilie: (...args) => dispatch(Actions.createFamilie(...args)),
  deleteFamilie: (...args) => dispatch(Actions.deleteFamilie(...args)),
  updateFamilie: (...args) => dispatch(Actions.updateFamilie(...args)),
});
class FamiliePage extends React.Component {
  static propTypes = {
    location: PropTypes.object,

    families: PropTypes.array,
    getFamilies: PropTypes.func,
    getFamilieById: PropTypes.func,
    createFamilie: PropTypes.func,
    deleteFamilie: PropTypes.func,
    updateFamilie: PropTypes.func,

    getRoles: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, families } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: families.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getFamilies, getRoles } = this.props;
    await Promise.all([
      getFamilies(),
      getRoles(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      families,
      roles,
      getFamilieById,
      createFamilie,
      deleteFamilie,
      updateFamilie,
      history,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperFamiliesPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        families={families}
        roles={roles}
        getFamilieById={getFamilieById}
        createFamilie={createFamilie}
        deleteFamilie={deleteFamilie}
        updateFamilie={updateFamilie}
        urlParams={urlParams}
        history={history}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FamiliePage);
