import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/EditTwoTone';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VerifiedUser from '@material-ui/icons/VerifiedUserTwoTone';

// styles
import styles from './styles';

class TableRolesRules extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
    onRulesClick: PropTypes.func,
    onEditClick: PropTypes.func,
  };

  render() {
    const { datum, onRulesClick, onEditClick } = this.props;
    const { classes } = this.props;

    return (
      <div
        className={classes.container}
      >
        <Tooltip title={`Edit ${datum.name}`}>
          <IconButton
            className={classes.button}
            aria-label="Edit"
            onClick={() => onEditClick(datum)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Paths\' Rules'}>
          <IconButton
            className={classes.button}
            aria-label="Edit"
            onClick={() => onRulesClick(datum)}
          >
            <VerifiedUser />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles)(TableRolesRules);
