import React from 'react';
import { connect } from 'react-redux';
import * as Routes from 'routes';
import PropTypes from 'prop-types';
import { routeNames } from 'constants/routeNames';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from 'themes/theme';

// actions
import * as Actions from 'actions';

// config
import {
  REACT_APP_CLIENT_NAME,
  REACT_APP_CLIENT_WEBSITE,
  REACT_APP_CLIENT_EMAIL,
  REACT_APP_CLIENT_COLOR,
  REACT_APP_API,
} from 'config';

// component
import WrapperRootPage from '../component/WrapperRootPage';

const mapStateToProps = state => ({
  errors: state.errors,
  app: state.app,
  user: state.user,
  userApps: state.userApps,
});

const mapDispatchToProps = dispatch => ({
  checkAccess: (...args) => dispatch(Actions.checkAccess(...args)),
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  storeResetPasswordToken: (...args) => dispatch(Actions.storeResetPasswordToken(...args)),
  signout: (...args) => dispatch(Actions.signout(...args)),
  requestResetPassword: (...args) => dispatch(Actions.requestResetPassword(...args)),
  resetPassword: (...args) => dispatch(Actions.resetPassword(...args)),
  signup: (...args) => dispatch(Actions.signup(...args)),
  validateEmail: (...args) => dispatch(Actions.validateEmail(...args)),
  setDarkMode: (...args) => dispatch(Actions.setDarkMode(...args)),
  getUserApps: (...args) => dispatch(Actions.getUserApps(...args)),
  stopImpersonate: (...args) => dispatch(Actions.stopImpersonate(...args)),
});

class RootPage extends React.Component {
  static propTypes = {
    checkAccess: PropTypes.func,
    userApps: PropTypes.array,
    getCurrentUser: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    getUserApps: PropTypes.func,
    stopImpersonate: PropTypes.func,

    user: PropTypes.object,
    errors: PropTypes.object,
    app: PropTypes.object,

    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    history: PropTypes.object,
  };

  static childContextTypes = {
    checkAccess: PropTypes.func,
  };

  getChildContext() {
    const { checkAccess } = this.props;
    return {
      checkAccess,
    };
  }

  componentWillMount() {
    console.log(`%cDeveloped by ${REACT_APP_CLIENT_NAME}`, `color: ${REACT_APP_CLIENT_COLOR}; font-size: 28px`);
    console.log('%cin Los Angeles, California', 'color: #607d8b; font-size: 18px');
    console.log(`%c${REACT_APP_CLIENT_EMAIL}`, 'color: #607d8b; font-size: 12px');
    console.log(`%c${REACT_APP_CLIENT_WEBSITE}`, 'color: #607d8b; font-size: 12px');
    console.log(`%cRunning on ${REACT_APP_API} API`, 'color: #607d8b; font-size: 12px');
  }

  getRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        if (route.onEnter()) {
          routes.unshift(route);
        }
      }
    }

    return routes;
  }

  getAllRoutes() {
    const { user } = this.props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        routes.unshift(route);
      }
    }

    return routes;
  }


  init() {
    console.log('init');
    const { getUserApps } = this.props;
    return Promise.all([
      getUserApps(),
    ]);
  }

  render() {
    const {
      app,
      user,
      getCurrentUser,
      checkAccess,
      signout,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      storeResetPasswordToken,
      resetPassword,
      setDarkMode,
      userApps,
      stopImpersonate,
      history,
    } = this.props;

    const muiTheme = createMuiTheme({
      palette: { ...theme, type: this.props.app.darkMode ? 'dark' : 'light' },
      typography: {
        useNextVariants: true,
      },
    });

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Router>
          <WrapperRootPage
            setDarkMode={setDarkMode}
            userApps={userApps}
            app={app}
            checkAccess={checkAccess}
            getCurrentUser={getCurrentUser}
            storeResetPasswordToken={storeResetPasswordToken}
            signout={signout}
            stopImpersonate={stopImpersonate}
            requestResetPassword={requestResetPassword}
            resetPassword={resetPassword}
            validateEmail={validateEmail}
            signup={signup}
            user={user}
            errors={errors}
            routes={this.getRoutes()}
            allRoutes={this.getAllRoutes()}
            init={this.init.bind(this)}
            history={history}
          />
        </Router>
      </MuiThemeProvider>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RootPage);
