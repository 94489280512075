export const routeNames = [
  "Default",
  "MyAccount",
  "Signin",
  "Demo",
  "Users",
  "Group",
  "Role",
  "Rule",
  "Debug",
  "Families",
  "Settings",
  "Gelatins",
  "Sources",
  "Localizations",
  "Languages",
  "RentalHouse",
];
