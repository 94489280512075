import {
  FAMILIES,
} from 'constants/routes';

// export function redirectAccordingToRole(accessLevel) {
export function redirectAccordingToRole() {
  // const firstRole = accessLevel[Object.keys(accessLevel)[0]][0];

  // try {
  //     let redirectRoute;
  //
  //     switch (firstRole) {
  //       case 'superadmin':
  //         redirectRoute = DEMO;
  //         break;
  //       case 'admin':
  //         redirectRoute = DEMO;
  //         break;
  //       case 'user':
  //         redirectRoute = DEMO;
  //         break;
  //       case 'anon':
  //         redirectRoute = DEMO;
  //         break;
  //       default:
  //         redirectRoute = '';
  //     }
  //
  //   return DEMO;
  // } catch (e) {
  //
  // }

  return FAMILIES;
}
