import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';

// components
import GroupsList from 'components/GroupsList';
import Loading from 'components/Loading';
import FormGroup from 'components/FormGroup';

// styles
import styles from './styles';

class WrapperGroupPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    groups: PropTypes.array,
    createGroup: PropTypes.func,
    deleteGroup: PropTypes.func,
    updateGroup: PropTypes.func,
    getGroupRoles: PropTypes.func,
    createGroupRole: PropTypes.func,
    deleteGroupRole: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedGroup: undefined,
      formGroupOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { groups } = this.props;

    switch (index) {
      case 0:
        return (
          <GroupsList
            groups={groups}
            onSelect={selectedGroup => this.setState({
              selectedGroup,
              formGroupOpen: true,
            })}
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createGroup,
      deleteGroup,
      updateGroup,
      refresh,
      roles,
      getGroupRoles,
      createGroupRole,
      deleteGroupRole,
    } = this.props;

    const {
      index,
      selectedGroup,
      formGroupOpen,
    } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {
          loading
            ? (
              <Loading
                message={'Fetching all Groups'}
              />
            )
            : (
              <div>
                <Helmet>
                  <title>Groups</title>
                  <meta name="MyAccount" content="MyAccount page" />
                </Helmet>
                <AppBar position="static" className={classes.appBar}>
                  <Tabs
                    value={index}
                    onChange={(e, v) => {
                      this.setState({
                        index: v,
                      });
                      history.push(`?index=${v}`);
                    }}

                    indicatorColor="primary"
                    textColor="primary"
                    scrollable
                    scrollButtons="auto"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      label="All"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                  </Tabs>
                </AppBar>
                <div className="container withAppBarAndTabs">
                  {
                    this.getStepContent()
                  }
                </div>
              </div>
            )
        }
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formGroupOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormGroup
          open={formGroupOpen}
          group={selectedGroup}
          close={() => {
            this.setState({
              formGroupOpen: false,
            });
            setTimeout(() => this.setState({
              selectedGroup: undefined,
            }), 200);
          }
          }
          createGroup={createGroup}
          deleteGroup={deleteGroup}
          updateGroup={updateGroup}
          roles={roles}
          getGroupRoles={getGroupRoles}
          createGroupRole={createGroupRole}
          deleteGroupRole={deleteGroupRole}
          refresh={refresh}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperGroupPage);
