import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';

// styles
import styles from './styles';

class GroupsList extends React.Component {
  static propTypes = {
    groups: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedGroup) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedGroup);
    }
  }

  render() {
    const { groups } = this.props;

    return (
      <Table
        data={groups}
        meta={[
          {
            path: 'name',
            title: 'Name',
            numeric: false,
          },
        ]}
        title={'Groups'}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(GroupsList);
