import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'predilux';
const families = `/${service}/families`;

export function getFamilies() {
  const url = `${families}`;
  return {
    method: GET,
    url,
  };
}

export function getFamilieById(id) {
  const url = `${families}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateFamilie(id, body) {
  const url = `${families}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteFamilie(id) {
  const url = `${families}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createFamilie(body) {
  const url = `${families}`;
  return {
    method: POST,
    url,
    body,
  };
}
