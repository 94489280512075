import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';

// components
import SettingsList from 'components/SettingsList';
import Loading from 'components/Loading';
import FormSetting from 'components/FormSetting';

// styles
import styles from './styles';

const indexDictinnary = {
  0: 'fps',
  1: 'iso',
  2: 'angle',
  3: 'tc',
  4: 'grid',
};

class WrapperSettingPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    settings: PropTypes.array,
    createSetting: PropTypes.func,
    deleteSetting: PropTypes.func,
    updateSetting: PropTypes.func,

    // roles
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSetting: undefined,
      formSettingOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { settings, updateSetting, refresh } = this.props;

    return (
      <SettingsList
        settings={settings.filter(s => s.type === indexDictinnary[index])}
        updateSetting={updateSetting}
        refresh={refresh}
        onSelect={selectedSetting => this.setState({
          selectedSetting,
          formSettingOpen: true,
        })}
      />
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSetting,
      deleteSetting,
      updateSetting,
      refresh,
      roles,
    } = this.props;

    const {
      index,
      selectedSetting,
      formSettingOpen,
    } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {
          loading
            ? (
              <Loading
                message={'Fetching all Settings'}
              />
            )
            : (
              <div>
                <Helmet>
                  <title>Settings</title>
                  <meta name="MyAccount" content="MyAccount page" />
                </Helmet>
                <AppBar position="static" className={classes.appBar}>
                  <Tabs
                    value={index}
                    onChange={(e, v) => {
                      this.setState({
                        index: v,
                      });
                      history.push(`?index=${v}`);
                    }}

                    indicatorColor="primary"
                    textColor="primary"
                    scrollable
                    scrollButtons="auto"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      label="Fps"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="Iso"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="Angle"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="Tc"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="Grid"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                  </Tabs>
                </AppBar>
                <div className="container withAppBarAndTabs">
                  {
                    this.getStepContent()
                  }
                </div>
              </div>
            )
        }
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formSettingOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormSetting
          open={formSettingOpen}
          setting={selectedSetting}
          close={() => {
            this.setState({
              formSettingOpen: false,
            });
            setTimeout(() => this.setState({
              selectedSetting: undefined,
            }), 200);
          }
          }
          createSetting={createSetting}
          deleteSetting={deleteSetting}
          updateSetting={updateSetting}
          refresh={refresh}
          roles={roles}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperSettingPage);
