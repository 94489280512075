import { networkAction } from 'helpers/network/networkAction';

import {
  GET_LANGUAGES,
  GET_LANGUAGE_BY_ID,
  DELETE_LANGUAGE,
  UPDATE_LANGUAGE,
  CREATE_LANGUAGE,
} from 'constants/languages';

import * as Api from 'api';

export const getLanguages = () => async dispatch => networkAction(
  dispatch,
  GET_LANGUAGES,
  Api.getLanguages,
  [],
);

export const getLanguageById = id => async dispatch => networkAction(
  dispatch,
  GET_LANGUAGE_BY_ID,
  Api.getLanguageById,
  [id],
);

export const deleteLanguage = id => async dispatch => networkAction(
  dispatch,
  DELETE_LANGUAGE,
  Api.deleteLanguage,
  [id],
);

export const createLanguage = body => async dispatch => networkAction(
  dispatch,
  CREATE_LANGUAGE,
  Api.createLanguage,
  [body],
);

export const updateLanguage = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_LANGUAGE,
  Api.updateLanguage,
  [id, body],
);
