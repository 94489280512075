import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'predilux';
const sources = `/${service}/sources`;

export function getSources() {
  const url = `${sources}`;
  return {
    method: GET,
    url,
  };
}

export function getSourceById(id) {
  const url = `${sources}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSource(id, body) {
  const url = `${sources}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSource(id) {
  const url = `${sources}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSource(body) {
  const url = `${sources}`;
  return {
    method: POST,
    url,
    body,
  };
}
