import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';
import TableRolesRules from 'components/TableRolesRules';

// styles
import styles from './styles';

class RolesList extends React.Component {
  static propTypes = {
    roles: PropTypes.array,
    onEdit: PropTypes.func,
    onRules: PropTypes.func,
  };

  onEditClick(selectedRole) {
    const { onEdit } = this.props;
    if (onEdit) {
      onEdit(selectedRole);
    }
  }

  onRulesClick(selectedRole) {
    const { onRules } = this.props;
    if (onRules) {
      onRules(selectedRole);
    }
  }

  render() {
    const { roles } = this.props;

    return (
      <Table
        data={roles}
        meta={[
          {
            path: 'name',
            title: 'Name',
            numeric: false,
          },
          {
            path: 'serviceName',
            title: 'Service Name',
            numeric: false,
          },
          {
            path: 'id',
            title: '',
            numeric: false,
            component: TableRolesRules,
            inject: {
              onEditClick: this.onEditClick.bind(this),
              onRulesClick: this.onRulesClick.bind(this),
            },
            width: 100,
          },
        ]}
        title={'Roles'}
      />
    );
  }
}

export default withStyles(styles)(RolesList);
