import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';

// components
import RolesList from 'components/RolesList';
import Loading from 'components/Loading';
import FormRole from 'components/FormRole';
import DialogRulesList from 'components/DialogRulesList';

// styles
import styles from './styles';

class WrapperRolePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    roles: PropTypes.array,
    createRole: PropTypes.func,
    deleteRole: PropTypes.func,
    updateRole: PropTypes.func,
    getRules: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,
    createRule: PropTypes.func,
    serviceNames: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedRole: undefined,
      formRoleOpen: false,
      listRulesOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { roles, serviceNames } = this.props;

    if (index === 0) {
      return (
        <RolesList
          roles={roles}
          onEdit={selectedRole => this.setState({
            selectedRole,
            formRoleOpen: true,
          })}
          onRules={(selectedRole) => {
            this.setState({
              selectedRole,
              listRulesOpen: true,
            });
          }}

        />
      );
    }

    return (
      <RolesList
        roles={roles.filter(r => r.serviceNameID === serviceNames[index - 1].id)}
        onEdit={selectedRole => this.setState({
          selectedRole,
          formRoleOpen: true,
        })}
        onRules={(selectedRole) => {
          this.setState({
            selectedRole,
            listRulesOpen: true,
          });
        }
      }
      />
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      createRole,
      deleteRole,
      updateRole,
      refresh,

      getRules,
      deleteRule,
      updateRule,
      createRule,

      serviceNames,
    } = this.props;

    const {
      index,
      selectedRole,
      formRoleOpen,
      listRulesOpen,
    } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {
          loading
            ? (
              <Loading
                message={'Fetching all Roles'}
              />
            )
            : (
              <div>
                <Helmet>
                  <title>Roles</title>
                  <meta name="MyAccount" content="MyAccount page" />
                </Helmet>
                <AppBar position="static" className={classes.appBar}>
                  <Tabs
                    value={index}
                    onChange={(e, v) => {
                      this.setState({
                        index: v,
                      });
                      history.push(`?index=${v}`);
                    }}

                    indicatorColor="primary"
                    textColor="primary"
                    scrollable
                    scrollButtons="auto"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      label="All"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    {
                      serviceNames.map(s => (
                        <Tab
                          label={(
                            <span
                              style={{
                                background: s.color,
                                color: 'white',
                                borderRadius: 12,
                                padding: '2px 8px 2px 8px',
                              }}
                            >
                              {s.name}
                            </span>
                          )}
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.tabSelected,
                          }}
                          key={s.name}
                        />
                      ))
                    }
                  </Tabs>
                </AppBar>
                <div className="container withAppBarAndTabs">
                  {
                    this.getStepContent()
                  }
                </div>
              </div>
            )
        }
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formRoleOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormRole
          open={formRoleOpen}
          role={selectedRole}
          close={() => {
            this.setState({
              formRoleOpen: false,
            });
            setTimeout(() => this.setState({
              selectedRole: undefined,
            }), 200);
          }
          }
          createRole={createRole}
          deleteRole={deleteRole}
          updateRole={updateRole}
          serviceNames={serviceNames}
          refresh={refresh}
        />
        <DialogRulesList
          open={listRulesOpen}
          close={() => {
            this.setState({
              listRulesOpen: false,
            });
            setTimeout(() => this.setState({
              selectedRole: undefined,
            }), 200);
          }}

          role={selectedRole}

          getRules={getRules}
          deleteRule={deleteRule}
          updateRule={updateRule}
          createRule={createRule}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperRolePage);
