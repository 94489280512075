import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withCookies } from 'react-cookie';
import Chip from '@material-ui/core/Chip';

import {
  REACT_APP_API,
  REACT_APP_PROD,
  REACT_APP_CI_COMMIT_SHA,
  REACT_APP_CI_COMMIT_TAG,
} from 'config';

// styles
import styles from './styles';

class WrapperDebugPage extends Component {
  static propTypes = {
    cookies: PropTypes.any,
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
  };

  static contextTypes = {
    WebsocketCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { WebsocketCenter } = this.context;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      info: WebsocketCenter.info(),
    };
    const interval = setInterval(() => this.setState({
      info: WebsocketCenter.info(),
    }), 2000);

    this.setState({ interval });
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }

  getBrowser() {
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name:'IE',version:(tem[1]||'')};
        }
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR|Edge\/(\d+)/)
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
      name: M[0],
      version: M[1]
    };
 }

  getStepContent() {
    const { classes, cookies, user } = this.props;
    const { index, info } = this.state;
    const { centrifuge } = info;

    const browser = this.getBrowser();
    const authServerToken = cookies.get('auth-server-token');

    switch (index) {
      case 0:
        return (
          <div
            className={classes.container}
          >
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <Paper className={classes.root}>
                  <Typography variant="h6">
                    Websocket
                  </Typography>
                  <hr />
                  <Typography variant="subtitle2">
                    Info
                  </Typography>
                  {
                    centrifuge
                    && (
                      <div>
                        <Typography variant="body2">
                          status: <b>{centrifuge._status}</b>
                        </Typography>
                        <Typography variant="body2">
                          retries: <b>{centrifuge._retries}</b>
                        </Typography>
                        <Typography variant="body2">
                          clientID: <b>{centrifuge._clientID}</b>
                        </Typography>
                        <Typography variant="body2">
                          pingInterval: <b>{centrifuge._pingInterval}</b>
                        </Typography>
                        <Typography variant="body2">
                          latency: <b>{centrifuge._latency}</b>
                        </Typography>
                        <Typography variant="body2">
                          timestamp: <b>{centrifuge._config.timestamp}</b>
                        </Typography>
                        <Typography variant="body2">
                          token: <b>{centrifuge._config.token}</b>
                        </Typography>
                        <Typography variant="body2">
                          url: <b>{centrifuge._config.url}</b>
                        </Typography>
                        <Typography variant="body2">
                          transport: <b>{centrifuge._transport.transport}</b>
                        </Typography>
                        <hr />
                        <Typography variant="subtitle2">
                          Subscriptions
                        </Typography>
                        {
                          Object.keys(centrifuge._subs).map(sub => {
                            const lastMessageID = centrifuge._lastMessageID[sub];
                            return (
                              <Typography variant="body2">
                                <b>{sub}</b>
                                {
                                  lastMessageID
                                  && (
                                    ` (Last messageID: ${lastMessageID})`
                                  )
                                }
                              </Typography>
                            );
                          })
                        }
                      </div>
                    )
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.root}>
                  <Typography variant="h6">
                    Browser
                  </Typography>
                  <hr />
                  <Typography variant="subtitle2">
                    Info
                  </Typography>
                  <Typography variant="body2">
                    Browser Name: <b>{browser.name}</b>
                  </Typography>
                  <Typography variant="body2">
                    Version: <b>{browser.version}</b>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.root}>
                  <Typography variant="h6">
                    API
                  </Typography>
                  <hr />
                  <Typography variant="subtitle2">
                    Info
                  </Typography>
                  <Typography variant="body2">
                    API Server Hostname: <b>{REACT_APP_API}</b>
                  </Typography>
                  <Typography variant="body2">
                    API Server Port: <b>443</b>
                  </Typography>
                  <Typography variant="body2">
                    Auth Server Token: <b>{authServerToken}</b>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.root}>
                  <Typography variant="h6">
                    App
                  </Typography>
                  <hr />
                  <Typography variant="subtitle2">
                    Info
                  </Typography>
                  <Typography variant="body2">
                    Is Production: <b>{REACT_APP_PROD}</b>
                  </Typography>
                  <Typography variant="body2">
                    Commit SHA: <b>{REACT_APP_CI_COMMIT_SHA}</b>
                  </Typography>
                  <Typography variant="body2">
                    Commit TAG: <b>{REACT_APP_CI_COMMIT_TAG}</b>
                  </Typography>
                </Paper>
              </Grid>
              {
                user && user.id
                && (
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.root}>
                      <Typography variant="h6">
                        User
                      </Typography>
                      <hr />
                      <Typography variant="subtitle2">
                        Info
                      </Typography>
                      <Typography variant="body2">
                        uuid: <b>{user.uuid}</b>
                      </Typography>
                      <Typography variant="body2">
                        id: <b>{user.id}</b>
                      </Typography>
                      <Typography variant="body2">
                        firstName: <b>{user.firstName}</b>
                      </Typography>
                      <Typography variant="body2">
                        lastName: <b>{user.lastName}</b>
                      </Typography>
                      <Typography variant="body2">
                        email: <b>{user.email}</b>
                      </Typography>
                      <Typography variant="body2">
                        createdAt: <b>{user.createdAt}</b>
                      </Typography>
                      <Typography variant="body2">
                        updatedAt: <b>{user.updatedAt}</b>
                      </Typography>
                      {
                        user && user.services
                        && (
                          <div>
                            <Typography variant="body2">
                              Services & Roles:
                            </Typography>
                            {
                              Object.keys(user.services).map(k => (
                                <div
                                  key={`service_${k}`}
                                >
                                  <Typography>
                                    <b>{k}</b>
                                  </Typography>
                                  {
                                    user.services[k].map(r => (
                                      <Chip
                                        key={`role_${k}_${r}`}
                                        label={r}
                                        className={classes.chip}
                                      />
                                    ))
                                  }
                                </div>
                              ))
                            }
                          </div>
                        )
                      }
                    </Paper>
                  </Grid>
                )
              }
            </Grid>
          </div>
        );
      default:
        return (
          <Typography variant="title">You have gone too far, Sonny Jim !</Typography>
        );
    }
  }

  render() {
    const {
      history,
    } = this.props;

    const {
      index,
    } = this.state;

    return (
      <div className="page">
        <Helmet>
          <title>Debug</title>
          <meta name="Debug" content="Debug page" />
        </Helmet>
        <div className="root">
          <AppBar position="absolute" color="default">
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push(`?index=${v}`);
              }}

              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: 'tabsRoot', indicator: 'tabsIndicator' }}
            >
              <Tab
                label="Debug"
                classes={{
                  root: 'tabRoot',
                  selected: 'tabSelected',
                }}
              />
            </Tabs>
          </AppBar>
          <div className="container withAppBarAndTabs">
            {
              this.getStepContent()
            }
          </div>
        </div>
      </div>
    );
  }
}
export default withCookies(withStyles(styles)(WrapperDebugPage));
