import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// styles
import styles from './styles';

class SourceValue extends Component {
  static propTypes = {
    classes: PropTypes.object,
    id: PropTypes.number,
    sourceID: PropTypes.number,
    category: PropTypes.string,
    focusable: PropTypes.bool,
    deleteSourceValue: PropTypes.func,
    updateSourceValue: PropTypes.func,
    sourceValue: PropTypes.object,
    handleChange: PropTypes.func,
    families: PropTypes.array,
    family: PropTypes.string,
    settings: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { sourceValue } = this.props;
    this.state = {
      ...sourceValue,
      loading: false,
      newIdentificationLoading: false,
    };
  }

  handleChange = name => (event) => { //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async deleteSourceValue() {
    const {
      deleteSourceValue,
      id,
      refreshSource,
      sourceID,
    } = this.props;

    await deleteSourceValue(id);
    refreshSource(sourceID);
  }

  async save(name, numeric) {
    const { updateSourceValue, refreshSource, sourceValue, sourceID } = this.props;

    if (sourceValue[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateSourceValue(
      sourceValue.id,
      {
        [name]: numeric ? Number(this.state[name]) : this.state[name],
      },
    );

    this.setState({
      loading: false,
    });

    refreshSource(sourceID);
  }

  render() {
    const {
      classes,
      category,
      focusable,
      families,
      family,
      settings,
    } = this.props;

    const {
      nameID,
      value,
      valueAlt,
      loading,
    } = this.state;


    let nameValues = settings && settings.filter(s => s.type === 'tc');
    if (category === 'grid') {
      nameValues = settings && settings.filter(s => s.type === 'grid');
    }
    let valueLabel = 'Value';
    let valueAltLabel = 'Value Alt';
    let valueAltDisabled = false;

    if (category === 'length') {
      valueLabel = 'Metric';
      valueAltLabel = 'US';
    }

    if ((category === 'grid') || (category === 'colorTemp') || (category === 'family')) {
      valueAltDisabled = true;
    }

    if ((category === 'family') && focusable) {
      valueLabel = 'Flood';
      valueAltLabel = 'Spot';
      valueAltDisabled = false;
    }

    return (
        <div className={classes.container}>
          <Grid
            container
            alignItems="center"
          >
            <Grid item xs={9}>
              <Grid
                container
                spacing={16}
                alignItems="center"
              >
                <Grid item xs={valueAltDisabled ? 6 : 4}>
                  {
                    category === 'family'
                      ? (
                        <FormControl fullWidth>
                          <InputLabel htmlFor="nameID-simple">Name</InputLabel>
                          <Select
                            disabled={loading}
                            value={nameID}
                            onChange={(e) => {
                              this.state.nameID = e.target.value; // eslint-disable-line
                              this.save('nameID');
                            }}
                            inputProps={{
                              name: 'nameID',
                              id: 'nameID-simple',
                            }}
                          >
                            {
                              families.filter(f => f.name === family).map(r => (
                                <MenuItem key={r.value} value={r.id}>
                                  {r.value}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      )
                      : (
                        <FormControl fullWidth>
                          <InputLabel htmlFor="role-simple">Name</InputLabel>
                          <Select
                            value={nameID}
                            disabled={loading}
                            onChange={(e) => {
                              this.state.nameID = e.target.value; // eslint-disable-line
                              this.save('nameID');
                            }}
                            inputProps={{
                              name: 'name',
                              id: 'name-simple',
                            }}
                          >
                            {
                              nameValues.map(v => (
                                <MenuItem key={v.value} value={v.id}>
                                  {v.value}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      )
                  }
                </Grid>
                <Grid item xs={valueAltDisabled ? 6 : 4}>
                  <Grid container>
                    <Grid item>
                      <TextField
                        id="value"
                        label={valueLabel}
                        value={value}
                        disabled={loading}
                        onKeyPress={(e) => { if (e.key === 'Enter') this.save('value', true); }}
                        onChange={this.handleChange('value', true)}
                        onBlur={() => this.save('value', true)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {
                  !valueAltDisabled
                    && (
                      <Grid item xs={4}>
                        <TextField
                          id="valueAlt"
                          disabled={loading}
                          label={valueAltLabel}
                          className={classes.textField}
                          value={valueAlt}
                          onKeyPress={(e) => { if (e.key === 'Enter') this.save('valueAlt', true); }}
                          onChange={this.handleChange('valueAlt', true)}
                          onBlur={() => this.save('valueAlt', true)}
                        />
                      </Grid>
                    )
                }
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={this.state.defaultValue}
                    onChange={() => {
                      this.state.defaultValue = !this.state.defaultValue; // eslint-disable-line
                      this.save('defaultValue');
                    }}
                    disabled={loading}
                    value="defaultValue"
                    color="primary"
                  />
                )}
                label="Default"
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'right' }}>
              <IconButton
                aria-label="Delete"
                className={classes.margin}
                onClick={this.deleteSourceValue.bind(this)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
    );
  }
}

export default withStyles(styles)(SourceValue);
