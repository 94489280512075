export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEMO = "/demo";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const USERS = "/users";
export const GROUP = "/groups";
export const ROLE = "/roles";
export const RULE = "/rules";
export const PATH = "/paths";
export const DEBUG = "/debug";
export const SUPPORT = "/support";
export const CUSTOMERS = "/customers";
export const PROJECT = "/projects";
export const CUSTOMER = "/customer";
export const SUPPORT_ITEM_TYPE = "/global-settings";
export const FAMILIES = "/families";
export const SETTINGS = "/settings";
export const GELATINS = "/gelatins";
export const SOURCES = "/sources";
export const LOCALIZATIONS = "/localizations";
export const LANGUAGES = "/languages";
export const RENTAL_HOUSE = "/rentalHouses";
