import React from 'react';
import PropTypes from 'prop-types';

// creative-tim
import Badge from 'creativeTim/Badge/Badge';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

// images
import AddUser from 'assets/img/add-user.png';

// regexp
import {
  AT_LEAST_1_NUMERICAL_CHAR,
  AT_LEAST_1_ALPHABETICAL_CHAR,
  AT_LEAST_8_CHAR,
  EMAIL,
} from 'constants/regexp';

// styles
import styles from './styles';

class FormUser extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    refresh: PropTypes.func,

    close: PropTypes.func,
    open: PropTypes.bool,

    user: PropTypes.object,

    createUser: PropTypes.func,
    updateUser: PropTypes.func,
    deleteUser: PropTypes.func,

    groups: PropTypes.array,
    getUserGroups: PropTypes.func,
    createUserGroup: PropTypes.func,
    deleteUserGroup: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      focused: false,
      groupLoading: false,
      userGroups: [],
      firstName: '',
      lastName: '',
      email: '',
      emailBlur: false,
      password: '',
      passwordConfirmation: '',
      eightChar: false,
      numerical: false,
      alphabetical: false,
      validEmail: false,
      match: false,
      showPassword: false,
      showPasswordRetype: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (nextProps.open && !open) {
      this.init(nextProps.user);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;
    const {
      passwordConfirmation,
      password,
    } = this.state;

    if (name === 'email') {
      let validEmail = false;
      if (EMAIL.test(value)) {
        validEmail = true;
      }

      this.setState({ validEmail });
    }

    if (name === 'password') {
      let eightChar = false;
      let numerical = false;
      let alphabetical = false;
      let match = false;

      if (AT_LEAST_8_CHAR.test(value)) {
        eightChar = true;
      }

      if (AT_LEAST_1_NUMERICAL_CHAR.test(value)) {
        numerical = true;
      }

      if (AT_LEAST_1_ALPHABETICAL_CHAR.test(value)) {
        alphabetical = true;
      }

      if (value === passwordConfirmation) {
        match = true;
      }

      this.setState({
        eightChar,
        numerical,
        alphabetical,
        match,
      });
    }

    if (name === 'passwordConfirmation') {
      let match = false;
      if (value === password) {
        match = true;
      }

      this.setState({ match });
    }

    this.setState({
      [name]: value,
    });
  };

  init(user) {
    this.setState({
      loading: false,
      focused: false,
      firstName: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      email: user ? user.email : '',
      password: '',
      passwordConfirmation: '',
      eightChar: false,
      emailBlur: false,
      numerical: false,
      alphabetical: false,
      validEmail: false,
      match: false,
      showPassword: false,
      showPasswordRetype: false,
    });

    if (user && user.id) {
      this.refresh(user);
    }
  }

  async refresh(user) {
    const { getUserGroups } = this.props;
    this.setState({ groupLoading: true });
    const resp = await getUserGroups(user.id);
    this.setState({ groupLoading: false });
    if (resp.success) {
      this.setState({
        userGroups: resp.payload,
      });
    }
  }

  delete() {
    const {
      deleteUser,
      user,
      refresh,
      close,
    } = this.props;
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this user, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: async () => {
            await deleteUser(user.id);
            refresh();
            close();
          },
        },
      },
    );
  }

  async save() {
    const {
      updateUser,
      createUser,
      user,
      refresh,
      close,
    } = this.props;

    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (user) {
      resp = await updateUser(
        user.id,
        {
          firstName,
          lastName,
          email,
        },
      );
    } else {
      resp = await createUser({
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
      });
    }

    this.setState({ loading: false });
    refresh();
    close();

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: user ? 'User has been updated.' : 'User has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  async deleteGroup(group) {
    const { deleteUserGroup, user } = this.props;
    await deleteUserGroup(user.id, group.id);
    this.refresh(user);
  }

  async addGroup(group) {
    const { createUserGroup, user } = this.props;
    await createUserGroup(user.id, { groupID: group.id });
    this.refresh(user);
  }

  render() {
    const {
      groups,
      classes,
      fullScreen,
      open,
      user,
      close,
    } = this.props;

    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      eightChar,
      numerical,
      alphabetical,
      match,
      focused,
      showPassword,
      showPasswordRetype,
      validEmail,
      emailBlur,
      loading,
      userGroups,
      groupLoading,
    } = this.state;

    const groupUserCanAdd = groups.filter(g => userGroups.find(
      ug => ug.groupID === g.id,
    ) === undefined);

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    user
                      ? `${user.firstName} ${user.lastName}`
                      : 'Let\'s Create a New User'
                  }
                </DialogTitle>
                <DialogContent>
                  {
                    user === undefined
                    && (
                      <div className={classes.userImage}>
                        <img alt="" src={AddUser} />
                      </div>
                    )
                  }
                  <DialogContentText>
                    {
                      user
                        ? 'To update the user, fill the form bellow :'
                        : 'To create a new user, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="firstName"
                        label="First Name *"
                        className={classes.textField}
                        value={firstName}
                        onChange={this.handleChange('firstName')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="lastName"
                        label="Last Name *"
                        className={classes.textField}
                        value={lastName}
                        onChange={this.handleChange('lastName')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={!validEmail && emailBlur}
                        onBlur={() => this.setState({ emailBlur: true })}
                        id="email"
                        label="Email *"
                        className={classes.textField}
                        value={email}
                        onChange={this.handleChange('email')}
                      />
                    </Grid>
                    {
                      user === undefined
                      && (
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.textField}>
                            <InputLabel htmlFor="adornment-password">Password</InputLabel>
                            <Input
                              id="adornment-password"
                              onFocus={() => this.setState({ focused: true })}
                              label="Password *"
                              type={showPassword ? 'text' : 'password'}
                              value={password}
                              onChange={this.handleChange('password')}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    tabIndex="-1"
                                    aria-label="Toggle password visibility"
                                    onClick={() => this.setState({ showPassword: !showPassword })}
                                    onMouseDown={e => e.preventDefault()}
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )
                    }
                    {
                      user === undefined
                      && (
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.textField}>
                            <InputLabel
                              htmlFor="adornment-password-retype"
                            >
                              Password Retype
                            </InputLabel>
                            <Input
                              onFocus={() => this.setState({ focused: true })}
                              type={showPasswordRetype ? 'text' : 'password'}
                              id="passwordConfirmation"
                              label="Retype Password *"
                              value={passwordConfirmation}
                              onChange={this.handleChange('passwordConfirmation')}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    tabIndex="-1"
                                    aria-label="Toggle password visibility"
                                    onClick={() => this.setState({
                                      showPasswordRetype: !showPasswordRetype,
                                    })}
                                    onMouseDown={e => e.preventDefault()}
                                  >
                                    {showPasswordRetype ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )
                    }
                    {
                      focused && user === undefined
                      && (
                        <Grid item xs={12}>
                          <Badge
                            color={eightChar ? 'success' : 'warning'}
                            className={classes.badge}
                          >
                            At least 8 characters
                          </Badge>
                          <Badge color={numerical ? 'success' : 'warning'}>
                            At least 1 digit
                          </Badge>
                          <Badge color={alphabetical ? 'success' : 'warning'}>
                            At least 1 letter
                          </Badge>
                          <Badge color={match ? 'success' : 'warning'}>
                            Passwords match
                          </Badge>
                        </Grid>
                      )
                    }
                    {
                      user !== undefined
                      && (
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Groups :
                          </Typography>
                          {
                            groupUserCanAdd.length
                            && (
                              <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="group-simple">Add Groups to User</InputLabel>
                                <Select
                                  inputProps={{
                                    name: 'Add Groups to User',
                                    id: 'group-simple',
                                  }}
                                  onChange={e => this.addGroup(e.target.value)}
                                >
                                  {
                                    groupUserCanAdd.map(g => (
                                      <MenuItem key={`role_${g.id}`} value={g}>
                                        {g.name}
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            )
                          }
                          {
                            groupLoading
                              ? (
                                <CircularProgress size={30} />
                              )
                              : (
                                <div>
                                  {
                                    userGroups && userGroups.map(g => (
                                      <Chip
                                        key={`chip_${g.id}`}
                                        className={classes.chip}
                                        label={g.groupName}
                                        onDelete={() => this.deleteGroup(g)}
                                      />
                                    ))
                                  }
                                  {
                                    userGroups.length === 0
                                    && (
                                      <Typography variant="caption">No Groups</Typography>
                                    )
                                  }
                                </div>
                              )
                          }
                        </Grid>
                      )
                    }
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    user
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    &nbsp;
                    {
                      user
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
        }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormUser));
