// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
// component
import WrapperSourcesPage from "../component/WrapperSourcesPage";

const mapStateToProps = (state) => ({
  sources: state.sources,
  roles: state.roles,
  families: state.families,
  settings: state.settings,
  languages: state.languages,
  rentalHouses: state.rentalHouses,
});

const mapDispatchToProps = (dispatch) => ({
  getSources: () => dispatch(Actions.getSources()),
  getFamilies: () => dispatch(Actions.getFamilies()),
  getRoles: () => dispatch(Actions.getRoles()),
  getLanguages: () => dispatch(Actions.getLanguages()),
  getSettings: () => dispatch(Actions.getSettings()),
  getSourceById: (...args) => dispatch(Actions.getSourceById(...args)),
  createSource: (...args) => dispatch(Actions.createSource(...args)),
  deleteSource: (...args) => dispatch(Actions.deleteSource(...args)),
  updateSource: (...args) => dispatch(Actions.updateSource(...args)),
  createSourceValue: (...args) => dispatch(Actions.createSourceValue(...args)),
  deleteSourceValue: (...args) => dispatch(Actions.deleteSourceValue(...args)),
  updateSourceValue: (...args) => dispatch(Actions.updateSourceValue(...args)),
  createSourceLocalization: (...args) =>
    dispatch(Actions.createSourceLocalization(...args)),
  deleteSourceLocalization: (...args) =>
    dispatch(Actions.deleteSourceLocalization(...args)),
  updateSourceLocalization: (...args) =>
    dispatch(Actions.updateSourceLocalization(...args)),
  getRentalHouses: (...args) => dispatch(Actions.getRentalHouses(...args)),
});
class SourcePage extends React.Component {
  static propTypes = {
    location: PropTypes.object,

    sources: PropTypes.array,
    getSources: PropTypes.func,
    getSourceById: PropTypes.func,
    createSource: PropTypes.func,
    deleteSource: PropTypes.func,
    updateSource: PropTypes.func,
    createSourceValue: PropTypes.func,
    deleteSourceValue: PropTypes.func,
    updateSourceValue: PropTypes.func,
    getRentalHouses: PropTypes.func,
    rentalHouses: PropTypes.array,

    getRoles: PropTypes.func,
    roles: PropTypes.array,

    getFamilies: PropTypes.func,
    families: PropTypes.array,

    getSettings: PropTypes.func,
    settings: PropTypes.array,

    getLanguages: PropTypes.func,
    languages: PropTypes.array,

    createSourceLocalization: PropTypes.func,
    deleteSourceLocalization: PropTypes.func,
    updateSourceLocalization: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, sources } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: sources.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const {
      getSources,
      getRoles,
      getFamilies,
      getSettings,
      getLanguages,
      getRentalHouses,
    } = this.props;

    await Promise.all([
      getSources(),
      getRoles(),
      getFamilies(),
      getSettings(),
      getLanguages(),
      getRentalHouses(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      sources,
      roles,
      families,
      languages,
      createSourceLocalization,
      deleteSourceLocalization,
      updateSourceLocalization,
      getSourceById,
      createSource,
      deleteSource,
      updateSource,
      createSourceValue,
      deleteSourceValue,
      updateSourceValue,
      history,
      settings,
      rentalHouses,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperSourcesPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        sources={sources}
        roles={roles}
        families={families}
        rentalHouses={rentalHouses}
        getSourceById={getSourceById}
        createSource={createSource}
        deleteSource={deleteSource}
        updateSource={updateSource}
        createSourceValue={createSourceValue}
        deleteSourceValue={deleteSourceValue}
        updateSourceValue={updateSourceValue}
        languages={languages}
        createSourceLocalization={createSourceLocalization}
        deleteSourceLocalization={deleteSourceLocalization}
        updateSourceLocalization={updateSourceLocalization}
        urlParams={urlParams}
        history={history}
        settings={settings}
      />
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourcePage);
