import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// styles
import styles from './styles';

class Loading extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string,
  };

  render() {
    const { classes, message } = this.props;

    return (
      <Grid
        container
        className={classes.root}
        alignContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <CircularProgress />
          <Typography variant="caption">
            {message}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Loading);
