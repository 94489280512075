import {
  GET_GELATINS,
} from 'constants/gelatins';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_GELATINS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
