import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormSource from "components/FormSource";
import Loading from "components/Loading";
// components
import SourcesList from "components/SourcesList";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
// styles
import styles from "./styles";

const indexDictinnary = {
  0: "led",
  1: "fluo",
  2: "hmi",
  3: "tungsten",
  4: "balloon",
};

class WrapperSourcePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    sources: PropTypes.array,
    rentalHouses: PropTypes.array,
    getSourceById: PropTypes.func,
    createSource: PropTypes.func,
    deleteSource: PropTypes.func,
    updateSource: PropTypes.func,
    createSourceValue: PropTypes.func,
    deleteSourceValue: PropTypes.func,
    updateSourceValue: PropTypes.func,
    families: PropTypes.array,
    settings: PropTypes.array,
    languages: PropTypes.array,
    createSourceLocalization: PropTypes.func,
    deleteSourceLocalization: PropTypes.func,
    updateSourceLocalization: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSource: undefined,
      formSourceOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { sources, families } = this.props;

    return (
      <SourcesList
        sources={
          index <= 4
            ? sources.filter((s) => s.type === indexDictinnary[index])
            : sources
        }
        onSelect={async (selectedSource) => {
          this.setState({
            sourceLoading: true,
            formSourceOpen: true,
          });
          this.refreshSource(selectedSource.id);
        }}
        families={families}
      />
    );
  }

  async refreshSource(id) {
    const { getSourceById } = this.props;
    const resp = await getSourceById(id);
    const selectedSource = resp.payload;
    this.setState({
      selectedSource,
      sourceLoading: false,
      refreshKey: new Date().getTime(),
    });
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSource,
      deleteSource,
      updateSource,
      createSourceValue,
      deleteSourceValue,
      updateSourceValue,
      refresh,
      families,
      settings,
      rentalHouses,
      languages,
      createSourceLocalization,
      deleteSourceLocalization,
      updateSourceLocalization,
    } = this.props;

    const {
      index,
      selectedSource,
      formSourceOpen,
      sourceLoading,
      refreshKey,
    } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {loading ? (
          <Loading message={"Fetching all Sources"} />
        ) : (
          <div>
            <Helmet>
              <title>Sources</title>
              <meta name="MyAccount" content="MyAccount page" />
            </Helmet>
            <AppBar position="static" className={classes.appBar}>
              <Tabs
                value={index}
                onChange={(e, v) => {
                  this.setState({
                    index: v,
                  });
                  history.push(`?index=${v}`);
                }}
                indicatorColor="primary"
                textColor="primary"
                scrollable
                scrollButtons="auto"
                classes={{
                  root: classes.tabsRoot,
                  indicator: classes.tabsIndicator,
                }}
              >
                <Tab
                  label="led"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
                <Tab
                  label="fluo"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
                <Tab
                  label="hmi"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
                <Tab
                  label="tungsten"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
                <Tab
                  label="balloon"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
                <Tab
                  label="all"
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              </Tabs>
            </AppBar>
            <div className="container withAppBarAndTabs">
              {this.getStepContent()}
            </div>
          </div>
        )}
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formSourceOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormSource
          open={formSourceOpen}
          source={selectedSource}
          close={() => {
            this.setState({
              formSourceOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedSource: undefined,
                }),
              200
            );
          }}
          rentalHouses={rentalHouses}
          createSource={createSource}
          deleteSource={deleteSource}
          updateSource={updateSource}
          createSourceValue={createSourceValue}
          deleteSourceValue={deleteSourceValue}
          updateSourceValue={updateSourceValue}
          sourceLoading={sourceLoading}
          refresh={refresh}
          refreshSource={this.refreshSource.bind(this)}
          families={families}
          settings={settings}
          languages={languages}
          createSourceLocalization={createSourceLocalization}
          deleteSourceLocalization={deleteSourceLocalization}
          updateSourceLocalization={updateSourceLocalization}
          refreshKey={refreshKey}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperSourcePage);
