import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';

// styles
import styles from './styles';

class FormLanguage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    language: PropTypes.object,

    createLanguage: PropTypes.func,
    updateLanguage: PropTypes.func,
    deleteLanguage: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: '',
      description: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.language);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(language) {
    this.setState({
      loading: false,
      name: language ? language.name : '',
      description: language ? language.description : '',
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this language, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
          callback: () => console.log('Callback'),
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      },
    );
  }

  async confirmDelete() {
    const {
      deleteLanguage,
      close,
      refresh,
      language,
    } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteLanguage(language.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Language has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateLanguage,
      createLanguage,
      language,
      refresh,
      close,
    } = this.props;

    const {
      name,
      description,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (language) {
      resp = await updateLanguage(
        language.id,
        {
          name,
          description,
        },
      );
    } else {
      resp = await createLanguage({
        name,
        description,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: language ? 'Language has been updated.' : 'Language has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      language,
      close,
    } = this.props;

    const {
      name,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    language
                      ? `${language.name}`
                      : 'Let\'s Create a New Language'
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      language
                        ? 'To update the language, fill the form bellow :'
                        : 'To create a new language, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name *"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange('name')}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    language
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    {
                      language
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
          }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormLanguage));
