import { networkAction } from 'helpers/network/networkAction';

import {
  GET_SOURCE_VALUES,
  GET_SOURCE_VALUE_BY_ID,
  DELETE_SOURCE_VALUE,
  UPDATE_SOURCE_VALUE,
  CREATE_SOURCE_VALUE,
} from 'constants/sourceValues';

import * as Api from 'api';

export const getSourceValues = () => async dispatch => networkAction(
  dispatch,
  GET_SOURCE_VALUES,
  Api.getSourceValues,
  [],
);

export const getSourceValueById = id => async dispatch => networkAction(
  dispatch,
  GET_SOURCE_VALUE_BY_ID,
  Api.getSourceValueById,
  [id],
);

export const deleteSourceValue = id => async dispatch => networkAction(
  dispatch,
  DELETE_SOURCE_VALUE,
  Api.deleteSourceValue,
  [id],
);

export const createSourceValue = body => async dispatch => networkAction(
  dispatch,
  CREATE_SOURCE_VALUE,
  Api.createSourceValue,
  [body],
);

export const updateSourceValue = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_SOURCE_VALUE,
  Api.updateSourceValue,
  [id, body],
);
