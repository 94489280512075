import { networkAction } from 'helpers/network/networkAction';

import {
  GET_SOURCE_LOCALIZATIONS,
  GET_SOURCE_LOCALIZATION_BY_ID,
  DELETE_SOURCE_LOCALIZATION,
  UPDATE_SOURCE_LOCALIZATION,
  CREATE_SOURCE_LOCALIZATION,
} from 'constants/sourceLocalizations';

import * as Api from 'api';

export const getSourceLocalizations = () => async dispatch => networkAction(
  dispatch,
  GET_SOURCE_LOCALIZATIONS,
  Api.getSourceLocalizations,
  [],
);

export const getSourceLocalizationById = id => async dispatch => networkAction(
  dispatch,
  GET_SOURCE_LOCALIZATION_BY_ID,
  Api.getSourceLocalizationById,
  [id],
);

export const deleteSourceLocalization = id => async dispatch => networkAction(
  dispatch,
  DELETE_SOURCE_LOCALIZATION,
  Api.deleteSourceLocalization,
  [id],
);

export const createSourceLocalization = body => async dispatch => networkAction(
  dispatch,
  CREATE_SOURCE_LOCALIZATION,
  Api.createSourceLocalization,
  [body],
);

export const updateSourceLocalization = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_SOURCE_LOCALIZATION,
  Api.updateSourceLocalization,
  [id, body],
);
