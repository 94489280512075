import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

// styles
import styles from './styles';

class FormGroup extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    close: PropTypes.func,
    open: PropTypes.bool,
    group: PropTypes.object,
    roles: PropTypes.array,
    createGroup: PropTypes.func,
    updateGroup: PropTypes.func,
    deleteGroup: PropTypes.func,
    getGroupRoles: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      roleLoading: false,
      name: '',
      groupRoles: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (nextProps.open && !open) {
      this.init(nextProps.group);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(group) {
    this.setState({
      loading: false,
      name: group ? group.name : '',
    });
    if (group && group.id) {
      this.refresh(group);
    }
  }

  async refresh(group) {
    const { getGroupRoles } = this.props;
    this.setState({ roleLoading: true });
    const resp = await getGroupRoles(group.id);
    this.setState({ roleLoading: false });
    if (resp.success) {
      this.setState({
        groupRoles: resp.payload,
      });
    }
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this group, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      },
    );
  }

  async confirmDelete() {
    const {
      deleteGroup,
      group,
      close,
      refresh,
    } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteGroup(group.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Group has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateGroup,
      createGroup,
      group,
      close,
      refresh,
    } = this.props;

    const {
      name,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (group) {
      resp = await updateGroup(
        group.id,
        {
          name,
        },
      );
    } else {
      resp = await createGroup({
        name,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: group ? 'Group has been updated.' : 'Group has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async deleteRole(role) {
    const { deleteGroupRole, group } = this.props;
    await deleteGroupRole(group.id, role.id);
    this.refresh(group);
  }

  async addRole(role) {
    const { createGroupRole, group } = this.props;
    await createGroupRole(group.id, { roleID: role.id });
    this.refresh(group);
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      group,
      close,
      roles,
    } = this.props;

    const {
      name,
      loading,
      groupRoles,
      roleLoading,
    } = this.state;

    const roleUserCanAdd = roles ? roles.filter(r => groupRoles.find(
      gr => gr.roleID === r.id,
    ) === undefined) : [];

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    group
                      ? `${group.name}`
                      : 'Let\'s Create a New Group'
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      group
                        ? 'To update the group, fill the form bellow :'
                        : 'To create a new group, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name *"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange('name')}
                      />
                    </Grid>
                    {
                      group
                      && (
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Roles :
                          </Typography>
                          {
                            roleUserCanAdd.length
                            && (
                              <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-simple">Add Role to Group</InputLabel>
                                <Select
                                  inputProps={{
                                    name: 'Add Role to Group',
                                    id: 'role-simple',
                                  }}
                                  onChange={e => this.addRole(e.target.value)}
                                >
                                  {
                                    roleUserCanAdd.map(r => (
                                      <MenuItem key={`role_${r.id}`} value={r}>
                                        <span
                                          style={{
                                            background: r.serviceColor,
                                            color: 'white',
                                            borderRadius: 12,
                                            padding: '2px 8px 2px 8px',
                                          }}
                                        >
                                          {`${r.serviceName}:${r.name}`}
                                        </span>
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            )
                          }
                          {
                            roleLoading
                              ? <CircularProgress size={30} />
                              : (
                                <div>
                                  {
                                    groupRoles && groupRoles.map(r => (
                                      <Chip
                                        key={`chip_${r.id}`}
                                        className={classes.chip}
                                        style={{
                                          background: r.serviceColor,
                                          color: 'white',
                                        }}
                                        label={`${r.serviceName}:${r.roleName}`}
                                        onDelete={() => this.deleteRole(r)}
                                      />
                                    ))
                                  }
                                  {
                                    groupRoles
                                    && groupRoles.length === 0
                                    && <Typography variant="caption">No Roles</Typography>
                                  }
                                </div>
                              )
                          }
                        </Grid>
                      )
                    }
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    group
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    {
                      group
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
        }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormGroup));
