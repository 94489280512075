import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'predilux';
const sourceValues = `/${service}/sourceValues`;

export function getSourceValues() {
  const url = `${sourceValues}`;
  return {
    method: GET,
    url,
  };
}

export function getSourceValueById(id) {
  const url = `${sourceValues}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSourceValue(id, body) {
  const url = `${sourceValues}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSourceValue(id) {
  const url = `${sourceValues}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSourceValue(body) {
  const url = `${sourceValues}`;
  return {
    method: POST,
    url,
    body,
  };
}
