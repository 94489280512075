import { networkAction } from 'helpers/network/networkAction';

import {
  GET_LOCALIZATIONS,
  GET_LOCALIZATION_BY_ID,
  DELETE_LOCALIZATION,
  UPDATE_LOCALIZATION,
  CREATE_LOCALIZATION,
} from 'constants/localizations';

import * as Api from 'api';

export const getLocalizations = () => async dispatch => networkAction(
  dispatch,
  GET_LOCALIZATIONS,
  Api.getLocalizations,
  [],
);

export const getLocalizationById = id => async dispatch => networkAction(
  dispatch,
  GET_LOCALIZATION_BY_ID,
  Api.getLocalizationById,
  [id],
);

export const deleteLocalization = id => async dispatch => networkAction(
  dispatch,
  DELETE_LOCALIZATION,
  Api.deleteLocalization,
  [id],
);

export const createLocalization = body => async dispatch => networkAction(
  dispatch,
  CREATE_LOCALIZATION,
  Api.createLocalization,
  [body],
);

export const updateLocalization = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_LOCALIZATION,
  Api.updateLocalization,
  [id, body],
);
