import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';

// styles
import styles from './styles';

class LocalizationsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    localizations: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedLocalization) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedLocalization);
    }
  }

  render() {
    const { localizations } = this.props;

    return (
      <Table
        data={localizations}
        meta={[
          {
            path: 'languageID',
            title: 'Language',
            numeric: false,
          },
          {
            path: 'key',
            title: 'Key',
            numeric: false,
          },
          {
            path: 'value',
            title: 'Value',
            numeric: false,
          },
        ]}
        title={'Localizations'}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(LocalizationsList);
