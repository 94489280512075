import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

// custom
import Require from 'components/Require';

// styles
import styles from './styles';

class WrapperDemoPage extends React.Component {
  static propTypes = {
    createUser: PropTypes.func,
    deleteUser: PropTypes.func,
    updateUser: PropTypes.func,
    classes: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
    WebsocketCenter: PropTypes.object,
    KeyboardEventCenter: PropTypes.object,
  };

  componentWillMount() {
    const { UploadCenter, WebsocketCenter, KeyboardEventCenter } = this.context;
    const sub = WebsocketCenter.subscribe('test');
    sub.on('message', this.websocketReceived.bind(this));
    UploadCenter.register(
      this.upload.bind(this),
      undefined,
      'image/jpeg, image/png',
    );
    KeyboardEventCenter.listen('ctrl+s', this.keyboardEvent.bind(this));
    KeyboardEventCenter.listen('ctrl+c', this.keyboardEvent.bind(this));
  }

  componentWillUnmount() {
    const { UploadCenter, WebsocketCenter, KeyboardEventCenter } = this.context;
    UploadCenter.disregard();
    WebsocketCenter.unsubscribe('test');
    KeyboardEventCenter.disregard('ctrl+s');
    KeyboardEventCenter.disregard('ctrl+c');
  }

  keyboardEvent(handler) {
    const {
      NotificationCenter,
    } = this.context;

    NotificationCenter.stack(
      {
        title: `${handler.key}`,
        subtitle: `${handler.key} event has been triggered`,
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: 'Ok',
          level: 'success',
        },
      },
    );
  }

  websocketReceived(message) {
    const {
      NotificationCenter,
    } = this.context;

    NotificationCenter.stack(
      {
        title: 'Websocket',
        subtitle: 'A new message has been received',
        body: JSON.stringify(message.data),
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: 'Ok',
          level: 'success',
        },
      },
    );
  }

  upload() {
    return new Promise((resolve) => {
      setTimeout(resolve, 5000);
    });
  }

  render() {
    const {
      NotificationCenter,
      UploadCenter,
    } = this.context;

    const {
      createUser,
      deleteUser,
      updateUser,
      classes,
    } = this.props;

    return (
      <div className={`container ${classes.background}`}>
        <Helmet>
          <title>Demo</title>
          <meta name="Demo" content="Demo page" />
        </Helmet>
        <Typography variant="h6">
          This is a test page. You can try to :
        </Typography>
        <Typography variant="body2">
          - Trigger a sweetAlert :
          <Button
            onClick={() => {
              NotificationCenter.sweetAlert(
                {
                  title: 'Good Job !',
                  subtitle: 'subtitle',
                  body: 'body',
                  success: true,
                  timestamp: new Date().getTime(),
                },
                {
                  cancel: {
                    label: 'Cancel',
                    level: 'default',
                  },
                  confirm: {
                    label: 'Got it !',
                    level: 'success',
                  },
                },
              );
            }}

            color="primary"
          >
            Sweet Alert
          </Button>
        </Typography>
        <Typography variant="body2">
          - Trigger a stack notification :
          <Button
            onClick={() => {
              NotificationCenter.stack(
                {
                  title: 'Done !',
                  subtitle: 'subtitle',
                  body: 'body',
                  timestamp: new Date().getTime(),
                },
                {
                  cancel: {
                    label: 'Cancel',
                    level: 'default',
                  },
                  confirm: {
                    label: 'Got it !',
                    level: 'success',
                  },
                },
              );
            }}

            color="primary"
          >
            Stack
          </Button>
        </Typography>
        <Typography variant="body2">
          - Upload a file by drag and dropping a file in the window or by clicking this button :
          <Button
            onClick={UploadCenter.openFileDialog}
            color="primary"
          >
            Open File Dialog
          </Button>
        </Typography>
        <Typography variant="body2">
          - Send a websocket message on the channel test via
          &nbsp;
          <a target="_blank" href="https://broker.versatile.la" rel="noopener noreferrer">
            Centrifugo
          </a>
        </Typography>
        <br />
        <Typography variant="body2">
          - Try the keyboard event ctrl+s
        </Typography>
        <br />
        <Typography variant="body2">
          - Auth Checker :
        </Typography>
        <br />
        <Require
          deleteUser={deleteUser}
        >
          <Button
            color="primary"
          >
            Can Delete User
          </Button>
        </Require>
        <Require
          createUser={createUser}
        >
          <Button
            color="primary"
          >
            Can Create User
          </Button>
        </Require>
        <Require
          createUser={updateUser}
        >
          <Button
            color="primary"
          >
            Can Update User
          </Button>
        </Require>
      </div>
    );
  }
}
export default withStyles(styles)(WrapperDemoPage);
