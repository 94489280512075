import { SOURCES } from 'constants/routes';
import { serviceRoles } from 'config/access';
import { checkAccess } from 'helpers/auth';
import SourcesPage from './container/SourcesPage';

export default function getSettingsRoute(userAuth) {
  const { predilux, account } = serviceRoles;
  const requireAuth = {
    [account.name]: [
      account.roles.superadmin,
    ],
    [predilux.name]: [
      predilux.roles.superadmin,
      predilux.roles.developer,
    ],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: SOURCES,
    withSidebar: true,
    withAppBar: true,
    component: SourcesPage,
  };
}
