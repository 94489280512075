import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';

// styles
import styles from './styles';

class LanguagesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    languages: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedLanguage) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedLanguage);
    }
  }

  render() {
    const { languages } = this.props;

    return (
      <Table
        data={languages}
        meta={[
          {
            path: 'name',
            title: 'Name',
            numeric: false,
            hidden: { mdDown: true },
          },
        ]}
        title={'Languages'}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(LanguagesList);
