export default () => ({
  container: {
    padding: 20,
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  floatingActionButton: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
});
