import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'predilux';
const sourceLocalizations = `/${service}/sourceLocalizations`;

export function getSourceLocalizations() {
  const url = `${sourceLocalizations}`;
  return {
    method: GET,
    url,
  };
}

export function getSourceLocalizationById(id) {
  const url = `${sourceLocalizations}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSourceLocalization(id, body) {
  const url = `${sourceLocalizations}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSourceLocalization(id) {
  const url = `${sourceLocalizations}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSourceLocalization(body) {
  const url = `${sourceLocalizations}`;
  return {
    method: POST,
    url,
    body,
  };
}
