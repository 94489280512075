import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

// component
import WrapperRulePage from '../component/WrapperRulePage';

const mapStateToProps = state => ({
  rules: state.rules,
  roles: state.roles,
});

const mapDispatchToProps = dispatch => ({
  getRules: () => dispatch(Actions.getRules()),
  getRoles: () => dispatch(Actions.getRoles()),
  getRuleById: (...args) => dispatch(Actions.getRuleById(...args)),
  createRule: (...args) => dispatch(Actions.createRule(...args)),
  deleteRule: (...args) => dispatch(Actions.deleteRule(...args)),
  updateRule: (...args) => dispatch(Actions.updateRule(...args)),
});
class RulePage extends React.Component {
  static propTypes = {
    location: PropTypes.object,

    rules: PropTypes.array,
    getRules: PropTypes.func,
    getRuleById: PropTypes.func,
    createRule: PropTypes.func,
    deleteRule: PropTypes.func,
    updateRule: PropTypes.func,

    getRoles: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, rules } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: rules.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getRules, getRoles } = this.props;
    await Promise.all([
      getRules(),
      getRoles(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      rules,
      roles,
      getRuleById,
      createRule,
      deleteRule,
      updateRule,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperRulePage
        refresh={this.refresh.bind(this)}
        loading={loading}
        rules={rules}
        roles={roles}
        getRuleById={getRuleById}
        createRule={createRule}
        deleteRule={deleteRule}
        updateRule={updateRule}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RulePage);
