// material-ui
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class RentalHousesList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    rentalHouses: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedRentalHouse) {
    const { onSelect } = this.props;
    this.setState({ selectedRentalHouse });
    if (onSelect) {
      onSelect(selectedRentalHouse);
    }
  }

  render() {
    const { rentalHouses } = this.props;

    return (
      <Table
        data={rentalHouses}
        meta={[
          {
            path: "logo",
            title: "",
            numeric: false,
            width: 80,
            render: (t, d) => {
              if (t) return <img src={t} style={{ width: 80 }} />;
              else return [];
            },
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "address",
            title: "Address",
            numeric: false,
          },
        ]}
        title={"RentalHouses"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(RentalHousesList);
