import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperLanguagePage from '../component/WrapperLanguagePage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  languages: state.languages,
});

const mapDispatchToProps = dispatch => ({
  getLanguages: () => dispatch(Actions.getLanguages()),
  getLanguageById: (...args) => dispatch(Actions.getLanguageById(...args)),
  createLanguage: (...args) => dispatch(Actions.createLanguage(...args)),
  deleteLanguage: (...args) => dispatch(Actions.deleteLanguage(...args)),
  updateLanguage: (...args) => dispatch(Actions.updateLanguage(...args)),
});
class LanguagePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    languages: PropTypes.array,
    getLanguages: PropTypes.func,
    getLanguageById: PropTypes.func,
    createLanguage: PropTypes.func,
    deleteLanguage: PropTypes.func,
    updateLanguage: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, languages } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: languages.length === 0,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getLanguages } = this.props;
    await Promise.all([
      getLanguages(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      languages,
      getLanguageById,
      createLanguage,
      deleteLanguage,
      updateLanguage,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperLanguagePage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        languages={languages}
        getLanguageById={getLanguageById}
        createLanguage={createLanguage}
        deleteLanguage={deleteLanguage}
        updateLanguage={updateLanguage}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LanguagePage);
