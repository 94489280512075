import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

// component
import WrapperGroupPage from '../component/WrapperGroupPage';

const mapStateToProps = state => ({
  groups: state.groups,
  roles: state.roles,
});

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(Actions.getGroups()),
  getGroupById: (...args) => dispatch(Actions.getGroupById(...args)),
  createGroup: (...args) => dispatch(Actions.createGroup(...args)),
  deleteGroup: (...args) => dispatch(Actions.deleteGroup(...args)),
  updateGroup: (...args) => dispatch(Actions.updateGroup(...args)),

  getRoles: () => dispatch(Actions.getRoles()),
  getGroupRoles: (...args) => dispatch(Actions.getGroupRoles(...args)),
  createGroupRole: (...args) => dispatch(Actions.createGroupRole(...args)),
  deleteGroupRole: (...args) => dispatch(Actions.deleteGroupRole(...args)),
});
class GroupPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,

    groups: PropTypes.array,
    getGroups: PropTypes.func,
    getGroupById: PropTypes.func,
    createGroup: PropTypes.func,
    deleteGroup: PropTypes.func,
    updateGroup: PropTypes.func,

    getRoles: PropTypes.func,
    getGroupRoles: PropTypes.func,
    createGroupRole: PropTypes.func,
    deleteGroupRole: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, groups } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: groups.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getGroups, getRoles } = this.props;
    await Promise.all([
      getGroups(),
      getRoles(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      groups,
      roles,
      getGroupById,
      createGroup,
      deleteGroup,
      updateGroup,
      deleteGroupRole,
      getGroupRoles,
      createGroupRole,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperGroupPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        groups={groups}
        roles={roles}
        getGroupById={getGroupById}
        createGroup={createGroup}
        deleteGroup={deleteGroup}
        updateGroup={updateGroup}
        getGroupRoles={getGroupRoles}
        deleteGroupRole={deleteGroupRole}
        createGroupRole={createGroupRole}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupPage);
