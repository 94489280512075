import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

// custom
import TableMethod from 'components/TableMethod';

import {
  DELETE,
  PUT,
  GET,
  POST,
} from 'constants/methods';

// styles
import styles from './styles';

class FormRule extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    rule: PropTypes.object,

    createRule: PropTypes.func,
    updateRule: PropTypes.func,
    deleteRule: PropTypes.func,
    refresh: PropTypes.func,

    roles: PropTypes.array,
    role: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      path: '',
      roleID: '',
      method: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (nextProps.open && !open) {
      this.init(nextProps.rule);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(rule) {
    const { role } = this.props;
    let r = '';
    if (role) {
      r = role.id;
    } else if (rule) {
      const { roleID } = rule;
      r = roleID;
    }

    this.setState({
      loading: false,
      path: rule ? rule.path : '',
      method: rule ? rule.method : '',
      roleID: r,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this rule, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      },
    );
  }

  async confirmDelete() {
    const {
      deleteRule,
      close,
      refresh,
      rule,
    } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteRule(rule.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Rule has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateRule,
      createRule,
      rule,
      refresh,
      close,
    } = this.props;

    const {
      path,
      roleID,
      method,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (rule) {
      resp = await updateRule(
        rule.id,
        {
          path,
          method,
          roleID,
        },
      );
    } else {
      resp = await createRule({
        path,
        method,
        roleID,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: rule ? 'Rule has been updated.' : 'Rule has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      rule,
      close,
      roles,
      role,
    } = this.props;

    const {
      path,
      loading,
      roleID,
      method,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    rule
                      ? `${rule.path}`
                      : 'Let\'s Create a New Rule'
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      rule
                        ? 'To update the rule, fill the form bellow :'
                        : 'To create a new rule, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <TextField
                        id="path"
                        label="Path *"
                        className={classes.textField}
                        value={path}
                        onChange={this.handleChange('path')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="role-simple">Method</InputLabel>
                        <Select
                          value={method}
                          onChange={this.handleChange('method')}
                          inputProps={{
                            name: 'method',
                            id: 'method-simple',
                          }}
                        >
                          <MenuItem key={GET} value={GET.toUpperCase()}>
                            <TableMethod datum={{ method: GET }} />
                          </MenuItem>
                          <MenuItem key={POST} value={POST.toUpperCase()}>
                            <TableMethod datum={{ method: POST }} />
                          </MenuItem>
                          <MenuItem key={PUT} value={PUT.toUpperCase()}>
                            <TableMethod datum={{ method: PUT }} />
                          </MenuItem>
                          <MenuItem key={DELETE} value={DELETE.toUpperCase()}>
                            <TableMethod datum={{ method: DELETE }} />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {
                      role === undefined
                      && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="role-simple">Role</InputLabel>
                            <Select
                              value={roleID}
                              onChange={this.handleChange('roleID')}
                              inputProps={{
                                name: 'role',
                                id: 'role-simple',
                              }}
                            >
                              {
                                roles && roles.map(r => (
                                  <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                      )
                    }
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    rule
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    &nbsp;
                    {
                      rule
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
        }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormRule));
