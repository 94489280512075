// routes
import FiberSmartRecord from "@material-ui/icons/FiberSmartRecordTwoTone";
import Flag from "@material-ui/icons/FlagTwoTone";
import GroupWork from "@material-ui/icons/GroupWorkTwoTone";
import Home from "@material-ui/icons/HomeTwoTone";
import Localizations from "@material-ui/icons/LocationSearchingTwoTone";
// icons
import Settings from "@material-ui/icons/SettingsTwoTone";
import WbIncandescent from "@material-ui/icons/WbIncandescentTwoTone";
import {
  FAMILIES,
  GELATINS,
  LANGUAGES,
  LOCALIZATIONS,
  RENTAL_HOUSE,
  SETTINGS,
  SOURCES,
} from "constants/routes";

export const menu = [
  {
    icon: GroupWork,
    label: "Families",
    path: FAMILIES,
    divider: true,
  },
  {
    icon: WbIncandescent,
    label: "Sources",
    path: SOURCES,
    divider: true,
  },
  {
    icon: FiberSmartRecord,
    label: "Gelatins",
    path: GELATINS,
    divider: true,
  },
  {
    icon: Home,
    label: "Rental Houses",
    path: RENTAL_HOUSE,
    divider: true,
  },
  {
    icon: Flag,
    label: "Languages",
    path: LANGUAGES,
    divider: true,
  },
  {
    icon: Localizations,
    label: "Localizations",
    path: LOCALIZATIONS,
    divider: true,
  },
  {
    icon: Settings,
    label: "Settings",
    path: SETTINGS,
    divider: true,
  },
];
