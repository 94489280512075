import { networkAction } from 'helpers/network/networkAction';

import {
  GET_FAMILIES,
  GET_FAMILIE_BY_ID,
  DELETE_FAMILIE,
  UPDATE_FAMILIE,
  CREATE_FAMILIE,
} from 'constants/families';

import * as Api from 'api';

export const getFamilies = () => async dispatch => networkAction(
  dispatch,
  GET_FAMILIES,
  Api.getFamilies,
  [],
);

export const getFamilieById = id => async dispatch => networkAction(
  dispatch,
  GET_FAMILIE_BY_ID,
  Api.getFamilieById,
  [id],
);

export const deleteFamilie = id => async dispatch => networkAction(
  dispatch,
  DELETE_FAMILIE,
  Api.deleteFamilie,
  [id],
);

export const createFamilie = body => async dispatch => networkAction(
  dispatch,
  CREATE_FAMILIE,
  Api.createFamilie,
  [body],
);

export const updateFamilie = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_FAMILIE,
  Api.updateFamilie,
  [id, body],
);
