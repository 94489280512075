import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Zoom from '@material-ui/core/Zoom';
import LinearProgress from '@material-ui/core/LinearProgress';

// open-source components
import Dropzone from 'react-dropzone';

// styles
import styles from './styles';

class Upload extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  static childContextTypes = {
    UploadCenter: PropTypes.object,
  };

  state = {
    dropzoneActive: false,
    upload: undefined,
    accept: '',
    callback: undefined,
  };

  getChildContext() {
    return {
      UploadCenter: {
        register: this.register.bind(this),
        disregard: this.disregard.bind(this),
        openFileDialog: this.open.bind(this),
      },
    };
  }

  async onDrop(acceptedFiles) {
    const { NotificationCenter } = this.context;
    const { upload, callback } = this.state;

    if (callback === undefined) {
      return;
    }

    this.setState({
      dropzoneActive: false,
    });
    NotificationCenter.stack(
      {
        title: 'Uploading files...',
        subtitle: acceptedFiles[0].name,
        body: <LinearProgress />,
        timestamp: new Date().getTime(),
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
        },
      },
    );

    await upload();
    callback();

    NotificationCenter.hide();
    NotificationCenter.stack(
      {
        title: 'Good Job !',
        success: true,
        subtitle: 'Your files have been uploaded',
        body: 'body',
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: 'Ok',
          level: 'success',
        },
      },
    );

    setTimeout(NotificationCenter.hide, 2000);
  }

  onDragEnter() {
    this.setState({
      dropzoneActive: true,
    });
  }

  onDragLeave() {
    this.setState({
      dropzoneActive: false,
    });
  }

  open() {
    if (this.Dropzone) {
      this.Dropzone.open();
    }
  }

  register(upload, callback, accept) {
    this.setState({
      upload,
      callback,
      accept,
    });
  }

  disregard() {
    this.setState({
      upload: undefined,
      callback: undefined,
      accept: undefined,
    });
  }

  render() {
    const { classes, children } = this.props;
    const { dropzoneActive, upload, accept } = this.state;

    return (
      <div className={classes.container}>
        <Dropzone
          ref={(c) => {
            this.Dropzone = c;
          }}

          disableClick
          accept={accept}
          disabled={upload === undefined}

          // accept="image/jpeg, image/png"
          className={classes.dropZone}
          onDrop={this.onDrop.bind(this)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
        >
          {children}
          {
            dropzoneActive
            && (
              <div
                className={classes.activeOverlay}
              >
                <Zoom in>
                  <CloudUpload
                    key="cloudUpload"
                    className={classes.cloudUpload}
                  />
                </Zoom>
                <Zoom in>
                  <Paper
                    key="uploadMessage"
                    className={classes.uploadMessage}
                  >
                    Drop files to upload them instantly.
                  </Paper>
                </Zoom>
              </div>
            )
          }
        </Dropzone>
      </div>
    );
  }
}

export default withStyles(styles)(Upload);
