import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// component
import WrapperLocalizationPage from '../component/WrapperLocalizationPage';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

const mapStateToProps = state => ({
  localizations: state.localizations,
  languages: state.languages,
});

const mapDispatchToProps = dispatch => ({
  getLocalizations: () => dispatch(Actions.getLocalizations()),
  getLocalizationById: (...args) => dispatch(Actions.getLocalizationById(...args)),
  createLocalization: (...args) => dispatch(Actions.createLocalization(...args)),
  deleteLocalization: (...args) => dispatch(Actions.deleteLocalization(...args)),
  updateLocalization: (...args) => dispatch(Actions.updateLocalization(...args)),
  getLanguages: (...args) => dispatch(Actions.getLanguages(...args)),
});
class LocalizationPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    localizations: PropTypes.array,
    getLocalizations: PropTypes.func,
    getLocalizationById: PropTypes.func,
    createLocalization: PropTypes.func,
    deleteLocalization: PropTypes.func,
    updateLocalization: PropTypes.func,

    getLanguages: PropTypes.func,
    languages: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, localizations, getLanguages } = this.props;
    getLanguages();
    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: localizations.length === 0,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.refresh();
  }

  async refresh() {
    const { getLocalizations } = this.props;
    await Promise.all([
      getLocalizations(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      localizations,
      languages,
      getLocalizationById,
      createLocalization,
      deleteLocalization,
      updateLocalization,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperLocalizationPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        localizations={localizations}
        languages={languages}
        getLocalizationById={getLocalizationById}
        createLocalization={createLocalization}
        deleteLocalization={deleteLocalization}
        updateLocalization={updateLocalization}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LocalizationPage);
