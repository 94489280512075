export default {
  dropZone: {
    height: '100vh',
    width: '100vw',
  },
  activeOverlay: {
    position: 'absolute',
    backdropFilter: 'blur(2px)',
    top: 0,
    background: 'rgba(33,150,243,0.5)',
    height: '100vh',
    width: '100vw',
    zIndex: 999,
  },
  rejectOverlay: {
    position: 'absolute',
    top: 0,
    background: 'rgba(244,67,54,0.3)',
    height: '100vh',
    width: '100vw',
    zIndex: 999,
  },
  uploadMessage: {
    background: '#2196f3',
    color: 'white',
    padding: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    width: 250,
    position: 'absolute',
    bottom: 20,
  },
  cloudUpload: {
    color: 'white',
    padding: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    position: 'absolute',
    bottom: 80,
    fontSize: 70,
  },
};
