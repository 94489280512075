export const serviceRoles = {
  account: {
    name: 'account',
    roles: {
      any: 'any',
      anon: 'anon',
      user: 'user',
      admin: 'admin',
      superadmin: 'superadmin',
      customer: 'customer',
    },
  },
  predilux: {
    name: 'predilux',
    roles: {
      any: 'any',
      anon: 'anon',
      developer: 'developer',
      superadmin: 'superadmin',
    },
  },
};
