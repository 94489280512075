import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'predilux';
const localizations = `/${service}/appLocalizations`;

export function getLocalizations() {
  const url = `${localizations}`;
  return {
    method: GET,
    url,
  };
}

export function getLocalizationById(id) {
  const url = `${localizations}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateLocalization(id, body) {
  const url = `${localizations}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLocalization(id) {
  const url = `${localizations}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLocalization(body) {
  const url = `${localizations}`;
  return {
    method: POST,
    url,
    body,
  };
}
