import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

// custom
import TableMethod from 'components/TableMethod';

import {
  DELETE,
  PUT,
  GET,
  POST,
} from 'constants/methods';

// styles
import styles from './styles';

class FormFamilie extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    familie: PropTypes.object,

    createFamilie: PropTypes.func,
    updateFamilie: PropTypes.func,
    deleteFamilie: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: '',
      value: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (nextProps.open && !open) {
      this.init(nextProps.familie);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(familie) {
    this.setState({
      loading: false,
      name: familie ? familie.name : '',
      value: familie ? familie.value : '',
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this familie, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      },
    );
  }

  async confirmDelete() {
    const {
      deleteFamilie,
      close,
      refresh,
      familie,
    } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteFamilie(familie.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Familie has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateFamilie,
      createFamilie,
      familie,
      refresh,
      close,
    } = this.props;

    const {
      name,
      value,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (familie) {
      resp = await updateFamilie(
        familie.id,
        {
          name,
          value,
        },
      );
    } else {
      resp = await createFamilie({
        name,
        value,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: familie ? 'Familie has been updated.' : 'Familie has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      familie,
      close,
    } = this.props;

    const {
      name,
      loading,
      value,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    familie
                      ? `${familie.value}`
                      : 'Let\'s Create a New Family'
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      familie
                        ? 'To update the family, fill the form bellow :'
                        : 'To create a new family, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        className={classes.textField}
                        value={value}
                        onChange={this.handleChange('value')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="role-simple">Type</InputLabel>
                        <Select
                          value={name}
                          onChange={this.handleChange('name')}
                          inputProps={{
                            name: 'name',
                            id: 'name-simple',
                          }}
                        >
                          <MenuItem key={'lens'} value={'lens'}>
                            Lens
                          </MenuItem>
                          <MenuItem key={'tube'} value={'tube'}>
                            Tube
                          </MenuItem>
                          <MenuItem key={'diff'} value={'diff'}>
                            Diff
                          </MenuItem>
                          <MenuItem key={'briese'} value={'briese'}>
                            Briese
                          </MenuItem>
                          <MenuItem key={'spotlight'} value={'spotlight'}>
                            Spotlight
                          </MenuItem>
                          <MenuItem key={'PAR Matrix'} value={'PAR Matrix'}>
                            PAR Matrix
                          </MenuItem>
                          <MenuItem key={'Max'} value={'Max'}>
                            Max
                          </MenuItem>
                          <MenuItem key={'open face'} value={'open face'}>
                            Open face
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    familie
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    &nbsp;
                    {
                      familie
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
        }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormFamilie));
