import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';

// components
import LanguagesList from 'components/LanguagesList';
import Loading from 'components/Loading';
import FormLanguage from 'components/FormLanguage';

// styles
import styles from './styles';

class WrapperLanguagePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    languages: PropTypes.array,
    createLanguage: PropTypes.func,
    deleteLanguage: PropTypes.func,
    updateLanguage: PropTypes.func,
    getLanguageRoles: PropTypes.func,
    createLanguageRole: PropTypes.func,
    deleteLanguageRole: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedLanguage: undefined,
      formLanguageOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { languages } = this.props;

    switch (index) {
      case 0:
        return (
          <LanguagesList
            languages={languages}
            onSelect={selectedLanguage => this.setState({
              selectedLanguage,
              formLanguageOpen: true,
            })}
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createLanguage,
      deleteLanguage,
      updateLanguage,
      refresh,
      roles,
      getLanguageRoles,
      createLanguageRole,
      deleteLanguageRole,
    } = this.props;

    const {
      index,
      selectedLanguage,
      formLanguageOpen,
    } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {
          loading
            ? (
              <Loading
                message={'Fetching all Languages'}
              />
            )
            : (
              <div>
                <Helmet>
                  <title>Languages</title>
                  <meta name="MyAccount" content="MyAccount page" />
                </Helmet>
                <AppBar position="static" className={classes.appBar}>
                  <Tabs
                    value={index}
                    onChange={(e, v) => {
                      this.setState({
                        index: v,
                      });
                      history.push(`?index=${v}`);
                    }}

                    indicatorColor="primary"
                    textColor="primary"
                    scrollable
                    scrollButtons="auto"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      label="All"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                  </Tabs>
                </AppBar>
                <div className="container withAppBarAndTabs">
                  {
                    this.getStepContent()
                  }
                </div>
              </div>
            )
        }
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formLanguageOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormLanguage
          open={formLanguageOpen}
          language={selectedLanguage}
          close={() => {
            this.setState({
              formLanguageOpen: false,
            });
            setTimeout(() => this.setState({
              selectedLanguage: undefined,
            }), 200);
          }
          }
          createLanguage={createLanguage}
          deleteLanguage={deleteLanguage}
          updateLanguage={updateLanguage}
          roles={roles}
          getLanguageRoles={getLanguageRoles}
          createLanguageRole={createLanguageRole}
          deleteLanguageRole={deleteLanguageRole}
          refresh={refresh}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperLanguagePage);
