import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';
import TableGelatin from 'components/TableGelatin';

// styles
import styles from './styles';

class GelatinsList extends React.Component {
  static propTypes = {
    gelatins: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedGelatin) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedGelatin);
    }
  }

  render() {
    const { gelatins } = this.props;

    return (
      <Table
        data={gelatins}
        rowStyle={d => d && !d.publish ? { opacity: 0.5 } : { opacity: 1 }}
        meta={[
          {
            path: 'color',
            title: 'Color',
            numeric: false,
            component: TableGelatin,
          },
          {
            path: 'ref',
            title: 'Ref',
            numeric: false,
          },
          {
            path: 'name',
            title: 'Name',
            numeric: false,
          },
          {
            path: 'description',
            title: 'Description',
            numeric: false,
          },
          {
            path: 'type',
            title: 'Type',
            numeric: false,
          },
          {
            path: 'brand',
            title: 'Brand',
            numeric: false,
          },
          {
            path: 'value',
            title: 'Value',
            numeric: true,
          },
        ]}
        title={'Gelatins'}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(GelatinsList);
