import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

// styles
import styles from './styles';

class FormLocalization extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    localization: PropTypes.object,
    languages: PropTypes.array,

    createLocalization: PropTypes.func,
    updateLocalization: PropTypes.func,
    deleteLocalization: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      key: '',
      value: '',
      languageID: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.localization);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(localization) {
    this.setState({
      loading: false,
      key: localization ? localization.key : '',
      value: localization ? localization.value : '',
      languageID: localization ? localization.languageID : '',
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this localization, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
          callback: () => console.log('Callback'),
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      },
    );
  }

  async confirmDelete() {
    const {
      deleteLocalization,
      close,
      refresh,
      localization,
    } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteLocalization(localization.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Localization has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateLocalization,
      createLocalization,
      localization,
      refresh,
      close,
    } = this.props;

    const {
      key,
      value,
      languageID,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (localization) {
      resp = await updateLocalization(
        localization.id,
        {
          key,
          value,
          languageID: Number(languageID),
        },
      );
    } else {
      resp = await createLocalization({
        key,
        value,
        languageID: Number(languageID),
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: localization ? 'Localization has been updated.' : 'Localization has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      localization,
      languages,
      close,
    } = this.props;

    const {
      key,
      languageID,
      value,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    localization
                      ? `${localization.key}`
                      : 'Let\'s Create a New Localization'
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      localization
                        ? 'To update the localization, fill the form bellow :'
                        : 'To create a new localization, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="languageID-simple">Language</InputLabel>
                        <Select
                          value={languageID}
                          onChange={this.handleChange('languageID')}
                          inputProps={{
                            name: 'languageID',
                            id: 'languageID-simple',
                          }}
                        >
                          {
                            languages && languages.map(l => (
                              <MenuItem key={l.name} value={l.id}>
                                {l.name}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="Key"
                        label="Key"
                        className={classes.textField}
                        value={key}
                        onChange={this.handleChange('key')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="value"
                        label="Value"
                        className={classes.textField}
                        value={value}
                        onChange={this.handleChange('value')}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    localization
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    {
                      localization
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
          }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormLocalization));
