import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';
import TableMethod from 'components/TableMethod';

// styles
import styles from './styles';

class FamiliesList extends React.Component {
  static propTypes = {
    families: PropTypes.array,
    onSelect: PropTypes.func,
    updateFamilie: PropTypes.func,
    refresh: PropTypes.func,
  };

  onSelect(selectedFamilie) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedFamilie);
    }
  }

  onReorder = (oldIndex, newIndex) => {
    const { updateFamilie, refresh, families } = this.props;
    const family = families[oldIndex];
    updateFamilie(family.id, { order: newIndex }).then(refresh);
  };

  render() {
    const { families } = this.props;

    return (
      <Table
        data={families}
        onReorder={this.onReorder.bind(this)}
        meta={[
          {
            path: 'value',
            title: 'Name',
            numeric: false,
          },
        ]}
        title={'Families'}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(FamiliesList);
