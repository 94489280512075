export default () => ({
  container: {
    padding: 5,
    background: '#f5f5f5',
    borderRadius: 4,
    marginBottom: 10,
  },
  textField: {
    width: '100%',
  },
});
