import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// custom
import Table from 'components/Table';
import TableMethod from 'components/TableMethod';

// styles
import styles from './styles';

class SettingsList extends React.Component {
  static propTypes = {
    settings: PropTypes.array,
    onSelect: PropTypes.func,
    refresh: PropTypes.func,
    updateSetting: PropTypes.func,
  };

  onSelect(selectedSetting) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedSetting);
    }
  }

  onReorder = (oldIndex, newIndex) => {
    const { updateSetting, refresh, settings } = this.props;
    const setting = settings[oldIndex];
    updateSetting(setting.id, { order: newIndex }).then(refresh);
  };

  render() {
    const { settings } = this.props;

    return (
      <Table
        data={settings}
        onReorder={this.onReorder.bind(this)}
        meta={[
          {
            path: 'value',
            title: 'Value',
            numeric: false,
          },
        ]}
        title={'Settings'}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(SettingsList);
