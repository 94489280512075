import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React from "react";
// open source
import { BlockPicker } from "react-color";
// styles
import styles from "./styles";

class FormGelatin extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    gelatin: PropTypes.object,

    createGelatin: PropTypes.func,
    updateGelatin: PropTypes.func,
    deleteGelatin: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      ref: "",
      description: "",
      color: "",
      brand: "",
      type: "",
      link: "",
      value: "",
      publish: false,
      common: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (nextProps.open && !open) {
      this.init(nextProps.gelatin);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(gelatin) {
    this.setState({
      loading: false,
      name: gelatin ? gelatin.name : "",
      ref: gelatin ? gelatin.ref : "",
      description: gelatin ? gelatin.description : "",
      color: gelatin ? gelatin.color : "",
      brand: gelatin ? gelatin.brand : "",
      type: gelatin ? gelatin.type : "",
      link: gelatin ? gelatin.link : "",
      value: gelatin ? gelatin.value : "",
      common: gelatin ? gelatin.common : false,
      publish: gelatin ? gelatin.publish : false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this gelatin, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteGelatin, close, refresh, gelatin } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteGelatin(gelatin.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Gelatin has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateGelatin,
      createGelatin,
      gelatin,
      refresh,
      close,
    } = this.props;

    const {
      name,
      ref,
      description,
      color,
      brand,
      type,
      publish,
      value,
      common,
      link,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (gelatin) {
      resp = await updateGelatin(gelatin.id, {
        name,
        ref,
        description,
        color,
        brand,
        type,
        publish,
        common,
        link,
        value: Number(value),
      });
    } else {
      resp = await createGelatin({
        name,
        ref,
        description,
        color,
        brand,
        type,
        publish,
        common,
        link,
        value: Number(value),
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: gelatin
          ? "Gelatin has been updated."
          : "Gelatin has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, gelatin, close } = this.props;

    const {
      loading,
      name,
      ref,
      description,
      color,
      brand,
      type,
      value,
      publish,
      common,
      link,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {gelatin ? `${gelatin.name}` : "Let's Create a New Gelatin"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {gelatin
                  ? "To update the gelatin, fill the form bellow :"
                  : "To create a new gelatin, fill the form bellow :"}
              </DialogContentText>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="role-simple">Brand</InputLabel>
                    <Select
                      value={brand}
                      onChange={this.handleChange("brand")}
                      inputProps={{
                        name: "type",
                        id: "type-simple",
                      }}
                    >
                      <MenuItem key={"LEE Filters"} value={"LEE Filters"}>
                        LEE Filters
                      </MenuItem>
                      <MenuItem key={"Rosco"} value={"Rosco"}>
                        Rosco
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="role-simple">Type</InputLabel>
                    <Select
                      value={type}
                      onChange={this.handleChange("type")}
                      inputProps={{
                        name: "type",
                        id: "type-simple",
                      }}
                    >
                      <MenuItem key={"diff"} value={"diff"}>
                        diff
                      </MenuItem>
                      <MenuItem key={"cc"} value={"cc"}>
                        cc
                      </MenuItem>
                      <MenuItem key={"effect"} value={"effect"}>
                        effect
                      </MenuItem>
                      <MenuItem key={"nd"} value={"nd"}>
                        nd
                      </MenuItem>
                      <MenuItem key={"other"} value={"other"}>
                        other
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name *"
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description *"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="ref"
                    label="Ref *"
                    className={classes.textField}
                    value={ref}
                    onChange={this.handleChange("ref")}
                  />
                </Grid>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={publish}
                          onChange={this.handleCheckboxChange("publish")}
                          value="publish"
                        />
                      }
                      label="Publish"
                    />
                  </FormGroup>
                </Grid>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={common}
                          onChange={this.handleCheckboxChange("common")}
                          value="common"
                        />
                      }
                      label="Common"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="value"
                    label="Value *"
                    className={classes.textField}
                    value={value}
                    onChange={this.handleChange("value")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="link"
                    label="Link"
                    className={classes.textField}
                    value={link}
                    onChange={this.handleChange("link")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BlockPicker
                    color={color}
                    onChange={(c) => {
                      this.setState({
                        color: c.hex,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {gelatin && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                &nbsp;
                {gelatin ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormGelatin));
