import { DELETE, GET, POST, PUT } from "constants/methods";
const rentalHouses = "/predilux/rentalHouses";

export function getRentalHouses() {
  const url = `${rentalHouses}`;
  return {
    method: GET,
    url,
  };
}

export function getRentalHouseById(id) {
  const url = `${rentalHouses}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateRentalHouse(id, body) {
  const url = `${rentalHouses}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteRentalHouse(id) {
  const url = `${rentalHouses}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createRentalHouse(body) {
  const url = `${rentalHouses}`;
  return {
    method: POST,
    url,
    body,
  };
}
