import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// styles
import styles from './styles';

class SourceLocalization extends Component {
  static propTypes = {
    classes: PropTypes.object,
    localization: PropTypes.object,
    refreshSource: PropTypes.func,
    deleteSourceLocalization: PropTypes.func,
    updateSourceLocalization: PropTypes.func,
    languages: PropTypes.array,
    sourceID: PropTypes.number,
  };

  constructor(...args) {
    super(...args);
    const { localization } = this.props;
    this.state = {
      ...localization,
      loading: false,
    };
  }

  handleChange = name => (event) => { //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async deleteLocalization() {
    const {
      deleteSourceLocalization,
      id,
      refreshSource,
      sourceID,
    } = this.props;

    await deleteSourceLocalization(id);
    refreshSource(sourceID);
  }

  async save(name, numeric) {
    const { updateSourceLocalization, refreshSource, localization, sourceID } = this.props;

    if (localization[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateSourceLocalization(
      localization.id,
      {
        [name]: numeric ? Number(this.state[name]) : this.state[name],
      },
    );

    this.setState({
      loading: false,
    });

    refreshSource(sourceID);
  }

  render() {
    const { classes, languages } = this.props;
    const {
      languageID,
      loading,
      name,
      description,
    } = this.state;

    return (
      <div className={classes.container}>
        <Grid
          container
          alignItems="center"
          spacing={8}
        >
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel htmlFor="role-simple">Language</InputLabel>
              <Select
                value={languageID}
                disabled={loading}
                onChange={(e) => {
                  this.state.languageID = e.target.value; // eslint-disable-line
                  this.save('languageID');
                }}
                inputProps={{
                  name: 'languageID',
                  id: 'languageID-simple',
                }}
              >
                {
                  languages.map(l => (
                    <MenuItem key={l.id} value={l.id}>
                      {l.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="value"
              label="Name"
              value={name}
              disabled={loading}
              onKeyPress={(e) => { if (e.key === 'Enter') this.save('name'); }}
              onChange={this.handleChange('name')}
              onBlur={() => this.save('name')}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="description"
              label="Description"
              value={description}
              multiline
              fullWidth
              disabled={loading}
              onKeyPress={(e) => { if (e.key === 'Enter') this.save('description'); }}
              onChange={this.handleChange('description')}
              onBlur={() => this.save('description')}
            />
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'right' }}>
            <IconButton
              aria-label="Delete"
              className={classes.margin}
              onClick={this.deleteLocalization.bind(this)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SourceLocalization);
