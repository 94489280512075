import rentalHouses from './rentalHouses';
import languages from './languages';
import localizations from './localizations';
import gelatins from './gelatins';
import settings from './settings';
import sourceValues from './sourceValues';
import sources from './sources';
import families from './families';
import rules from './rules';
import roles from './roles';
import groups from './groups';
import users from './users';
import errors from './errors';
import app from './app';
import userApps from './userApps';
import user from './user';
import serviceNames from './serviceNames';
import { combineReducers } from 'redux'; //eslint-disable-line
export default combineReducers({
  errors,
  user,
  app,
  users,
  groups,
  roles,
  rules,
  serviceNames,
  userApps,
  families,
  sources,
  sourceValues,
  settings,
  gelatins,
  localizations,
  languages,
  rentalHouses,
});
