import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'predilux';
const gelatins = `/${service}/gelatins`;

export function getGelatins() {
  const url = `${gelatins}`;
  return {
    method: GET,
    url,
  };
}

export function getGelatinById(id) {
  const url = `${gelatins}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateGelatin(id, body) {
  const url = `${gelatins}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteGelatin(id) {
  const url = `${gelatins}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGelatin(body) {
  const url = `${gelatins}`;
  return {
    method: POST,
    url,
    body,
  };
}
