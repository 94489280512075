import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

// styles
import styles from './styles';

class FormRole extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    role: PropTypes.object,

    createRole: PropTypes.func,
    updateRole: PropTypes.func,
    deleteRole: PropTypes.func,
    refresh: PropTypes.func,

    serviceNames: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: '',
      serviceNameID: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { open } = this.props;
    if (nextProps.open && !open) {
      this.init(nextProps.role);
    }
  }

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(role) {
    this.setState({
      loading: false,
      name: role ? role.name : '',
      serviceNameID: role ? role.serviceNameID : '',
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this role, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      },
    );
  }

  async confirmDelete() {
    const {
      deleteRole,
      close,
      refresh,
      role,
    } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteRole(role.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Role has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateRole,
      createRole,
      role,
      refresh,
      close,
    } = this.props;

    const {
      name,
      serviceNameID,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (role) {
      resp = await updateRole(
        role.id,
        {
          name,
          serviceNameID,
        },
      );
    } else {
      resp = await createRole({
        name,
        serviceNameID,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: role ? 'Role has been updated.' : 'Role has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      role,
      close,
      serviceNames,
    } = this.props;

    const {
      name,
      loading,
      serviceNameID,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    role
                      ? `${role.name}`
                      : 'Let\'s Create a New Role'
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      role
                        ? 'To update the role, fill the form bellow :'
                        : 'To create a new role, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name *"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange('name')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="serviceName-simple">serviceName</InputLabel>
                        <Select
                          value={serviceNameID}
                          onChange={this.handleChange('serviceNameID')}
                          inputProps={{
                            name: 'serviceName',
                            id: 'serviceName-simple',
                          }}
                        >
                          {
                            serviceNames.map(g => (
                              <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    role
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    &nbsp;
                    {
                      role
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
        }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormRole));
