import { ROLE } from 'constants/routes';
import { serviceRoles } from 'config/access';
import { checkAccess } from 'helpers/auth';
import RolePage from './container/RolePage';

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [
      account.roles.superadmin,
    ],
  };
  return {
    path: ROLE,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: RolePage,
  };
}
