import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Add from "@material-ui/icons/AddCircleOutline";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import SourceLocalization from "components/SourceLocalization";
// custom
import SourceValue from "components/SourceValue";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import SwipeableViews from "react-swipeable-views";
// styles
import styles from "./styles";

function TabContainer({ children, dir }) {
  return (
    <div component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

class FormSource extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    source: PropTypes.object,

    createSource: PropTypes.func,
    updateSource: PropTypes.func,
    deleteSource: PropTypes.func,
    createSourceValue: PropTypes.func,
    updateSourceValue: PropTypes.func,
    deleteSourceValue: PropTypes.func,
    sourceLoading: PropTypes.bool,
    rentalHouses: PropTypes.array,

    refresh: PropTypes.func,
    refreshSource: PropTypes.func,

    families: PropTypes.array,
    settings: PropTypes.array,
    refreshKey: PropTypes.number,

    languages: PropTypes.array,
    createSourceLocalization: PropTypes.func,
    deleteSourceLocalization: PropTypes.func,
    updateSourceLocalization: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      tab: 0,
      loading: false,
      name: "",
      key: "",
      manufacturer: "",
      verificationDate: "",
      verificationLocation: "",
      power: "",
      radius: "",
      type: "",
      focusable: false,
      candela: "",
      dimmable: false,
      publish: false,
      verified: false,
      family: "",
      comments: "",
      rentalHouseID: "",
      defaultValueID: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.sourceLoading && this.props.sourceLoading) {
      this.init(nextProps.source);
    }
    if (nextProps.open && !this.props.open) {
      this.init();
    }
  }

  handleChange = (name) => (event) => {
    const { source } = this.props;
    const { target } = event;
    const { value } = target;

    console.log(name, value);

    if (name === "family" && source) {
      this.updateFamily(value);
    }

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;
    this.setState({
      [name]: checked,
    });
  };

  init(source) {
    this.setState({
      loading: false,
      name: source ? source.name : "",
      key: source ? source.key : "",
      manufacturer: source ? source.manufacturer : "",
      verificationDate: source ? source.verificationDate : "",
      verificationLocation: source ? source.verificationLocation : "",
      rentalHouseID: source ? source.rentalHouseID : "",
      power: source ? source.power : 0,
      radius: source ? source.radius : 0,
      type: source ? source.type : "",
      focusable: source ? source.focusable : false,
      verified: source ? source.verified : false,
      dimmable: source ? source.dimmable : false,
      family: source ? source.family : "",
      candela: source ? source.candela : "",
      comments: source ? source.comments : "",
      defaultValueID: source ? source.defaultValueID : "",
      publish: source ? source.publish : false,
      tab: 0,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this source, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteSource, close, refresh, source } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteSource(source.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Source has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async updateFamily(family) {
    const { source, updateSource } = this.props;
    await updateSource(source.id, {
      family,
    });
  }

  async save() {
    const {
      updateSource,
      createSource,
      source,
      refresh,
      close,
      refreshKey,
    } = this.props;

    const {
      name,
      key,
      manufacturer,
      verificationDate,
      verificationLocation,
      power,
      radius,
      type,
      focusable,
      candela,
      family,
      dimmable,
      comments,
      verified,
      publish,
      defaultValueID,
      rentalHouseID,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (source) {
      resp = await updateSource(source.id, {
        name,
        key,
        manufacturer,
        verificationDate,
        verificationLocation,
        power: Number(power),
        radius: Number(radius),
        rentalHouseID: rentalHouseID ? Number(rentalHouseID) : undefined,
        type,
        focusable,
        publish,
        verified,
        defaultValueID,
        candela: candela && candela.length ? Number(candela) : undefined,
        family,
        dimmable,
        comments,
      });
    } else {
      resp = await createSource({
        name,
        key,
        defaultValueID,
        verificationDate,
        verificationLocation,
        publish,
        verified,
        manufacturer,
        power: Number(power),
        radius: Number(radius),
        rentalHouseID: rentalHouseID ? Number(rentalHouseID) : undefined,
        type,
        focusable,
        candela: candela && candela.length ? Number(candela) : undefined,
        family,
        dimmable,
        comments,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: source ? "Source has been updated." : "Source has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async addSourceValue(category) {
    const { createSourceValue, source, refreshSource } = this.props;
    await createSourceValue({
      sourceID: source.id,
      type: category,
    });
    refreshSource(source.id);
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      source,
      close,
      sourceLoading,
      deleteSourceValue,
      updateSourceValue,
      updateSource,
      refreshSource,
      families,
      settings,
      refreshKey,
      languages,
      createSourceLocalization,
      deleteSourceLocalization,
      updateSourceLocalization,
      rentalHouses,
    } = this.props;

    const {
      loading,
      name,
      key,
      manufacturer,
      verificationDate,
      verificationLocation,
      power,
      type,
      focusable,
      family,
      publish,
      verified,
      candela,
      dimmable,
      comments,
      radius,
      rentalHouseID,
      defaultValueID,
    } = this.state;

    let values = [];
    if (source && source.colorTemps) values = values.concat(source.colorTemps);
    if (source && source.familyValues)
      values = values.concat(source.familyValues);
    if (source && source.gridValues)
      values = values.concat(source.familyValues);

    return (
      <Dialog
        fullScreen={fullScreen}
        classes={{ paper: classes.dialogContainer }}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading || sourceLoading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {source ? `${source.name}` : "Let's Create a New Source"}
            </DialogTitle>
            <Tabs
              value={this.state.tab}
              onChange={(event, value) => {
                this.setState({ tab: value });
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="General" />
              {source !== undefined
                ? [
                    <Tab
                      key="families Tab"
                      disabled={family === ""}
                      label={
                        <Badge
                          className={classes.padding}
                          color="primary"
                          badgeContent={
                            source && source.familyValues
                              ? source.familyValues.length
                              : 0
                          }
                        >
                          Family Values
                        </Badge>
                      }
                    />,
                    <Tab
                      key="color temp tab"
                      label={
                        <Badge
                          className={classes.padding}
                          color="primary"
                          badgeContent={
                            source && source.colorTemps
                              ? source.colorTemps.length
                              : 0
                          }
                        >
                          Color Values
                        </Badge>
                      }
                    />,
                    <Tab
                      key="grid tab"
                      label={
                        <Badge
                          className={classes.padding}
                          color="primary"
                          badgeContent={
                            source && source.gridValues
                              ? source.gridValues.length
                              : 0
                          }
                        >
                          Grid Values
                        </Badge>
                      }
                    />,
                    <Tab
                      key="grid tab"
                      label={
                        <Badge
                          className={classes.padding}
                          color="primary"
                          badgeContent={
                            source && source.localizations
                              ? source.localizations.length
                              : 0
                          }
                        >
                          Localizations
                        </Badge>
                      }
                    />,
                  ]
                : []}
            </Tabs>
            <DialogContent style={{ paddingTop: 20 }}>
              <SwipeableViews
                axis={"x"}
                index={this.state.tab}
                onChangeIndex={(index) => this.setState({ tab: index })}
              >
                <TabContainer>
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange("name")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="role-simple">
                          Technology
                        </InputLabel>
                        <Select
                          value={type}
                          onChange={this.handleChange("type")}
                          inputProps={{ name: "type", id: "type-simple" }}
                        >
                          <MenuItem key={"led"} value={"led"}>
                            Led
                          </MenuItem>
                          <MenuItem key={"fluo"} value={"fluo"}>
                            Fluo
                          </MenuItem>
                          <MenuItem key={"hmi"} value={"hmi"}>
                            Hmi
                          </MenuItem>
                          <MenuItem key={"tungsten"} value={"tungsten"}>
                            Tungsten
                          </MenuItem>
                          <MenuItem key={"balloon"} value={"balloon"}>
                            Balloon
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="key"
                        label="Key"
                        className={classes.textField}
                        value={key}
                        onChange={this.handleChange("key")}
                      />
                    </Grid>
                    <Grid item>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={verified}
                              onChange={this.handleCheckboxChange("verified")}
                              value="verified"
                            />
                          }
                          label={
                            <span>
                              <i class="fas fa-badge-check" /> Verified
                            </span>
                          }
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        disabled={!verified}
                        id="rentalHouse"
                        label="Rental House"
                        className={classes.textField}
                        value={rentalHouseID}
                        select
                        onChange={this.handleChange("rentalHouseID")}
                      >
                        {rentalHouses.map((rh) => (
                          <MenuItem value={rh.id} key={rh.id}>
                            {rh.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        type="date"
                        disabled={!verified}
                        label="verification Date"
                        className={classes.textField}
                        defaultValue={moment().format("YYYY-MM-DD")}
                        value={moment(verificationDate)
                          .utc()
                          .format("YYYY-MM-DD")}
                        onChange={this.handleChange("verificationDate")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        disabled={!verified}
                        id="verificationLocation"
                        label="verificationLocation"
                        className={classes.textField}
                        value={verificationLocation}
                        onChange={this.handleChange("verificationLocation")}
                      />
                    </Grid>
                    <Grid item>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={publish}
                              onChange={this.handleCheckboxChange("publish")}
                              value="publish"
                            />
                          }
                          label="Publish"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="role-simple">Family</InputLabel>
                        <Select
                          value={family}
                          onChange={this.handleChange("family")}
                          inputProps={{
                            name: "family",
                            id: "tyfamilype-simple",
                          }}
                        >
                          <MenuItem key={"none"} value={""}>
                            No Family
                          </MenuItem>
                          <MenuItem key={"lens"} value={"lens"}>
                            Lens
                          </MenuItem>
                          <MenuItem key={"tube"} value={"tube"}>
                            Tube
                          </MenuItem>
                          <MenuItem key={"diff"} value={"diff"}>
                            Diff
                          </MenuItem>
                          <MenuItem key={"briese"} value={"briese"}>
                            Briese
                          </MenuItem>
                          <MenuItem key={"spotlight"} value={"spotlight"}>
                            Spotlight
                          </MenuItem>
                          <MenuItem key={"PAR Matrix"} value={"PAR Matrix"}>
                            PAR Matrix
                          </MenuItem>
                          <MenuItem key={"Max"} value={"Max"}>
                            Max
                          </MenuItem>
                          <MenuItem key={"open face"} value={"open face"}>
                            Open face
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="power"
                        label="Power"
                        className={classes.textField}
                        value={power}
                        onChange={this.handleChange("power")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="candela"
                        label="Candela"
                        className={classes.textField}
                        value={candela}
                        onChange={this.handleChange("candela")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="radius"
                        label="Radius"
                        className={classes.textField}
                        value={radius}
                        onChange={this.handleChange("radius")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="manufacturer"
                        label="Manufacturer"
                        className={classes.textField}
                        value={manufacturer}
                        onChange={this.handleChange("manufacturer")}
                      />
                    </Grid>
                    <Grid item>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={focusable}
                              onChange={this.handleCheckboxChange("focusable")}
                              value="Focusable"
                              color="primary"
                            />
                          }
                          label="Focusable"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={dimmable}
                              onChange={this.handleCheckboxChange("dimmable")}
                              value="Dimmable"
                              color="primary"
                            />
                          }
                          label="Dimmable"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        background: "#fff9c4",
                        border: "solid 1px #fdd835",
                        borderRadius: 4,
                      }}
                    >
                      <TextField
                        id="comments"
                        label="Comments"
                        className={classes.textField}
                        value={comments}
                        onChange={this.handleChange("comments")}
                      />
                    </Grid>
                  </Grid>
                </TabContainer>
                {source !== undefined
                  ? [
                      <TabContainer key="family">
                        {source &&
                          source.familyValues &&
                          source.familyValues.map((f) => (
                            <SourceValue
                              defaultValueID={defaultValueID}
                              handleChange={this.handleChange.bind(this)}
                              refreshSource={refreshSource}
                              deleteSourceValue={deleteSourceValue}
                              updateSourceValue={updateSourceValue}
                              category="family"
                              focusable={focusable}
                              families={families}
                              family={family}
                              key={`${f.id}_${refreshKey}`}
                              sourceValue={f}
                              sourceID={source.id}
                            />
                          ))}
                        <Chip
                          label="New Family Value"
                          onClick={() => this.addSourceValue("family")}
                          avatar={
                            <Avatar style={{ background: "none" }}>
                              <Add />
                            </Avatar>
                          }
                          variant="outlined"
                        />
                      </TabContainer>,
                      <TabContainer key="color">
                        {source &&
                          source.colorTemps &&
                          source.colorTemps.map((c) => (
                            <SourceValue
                              settings={settings}
                              refreshSource={refreshSource}
                              deleteSourceValue={deleteSourceValue}
                              updateSourceValue={updateSourceValue}
                              category="colorTemp"
                              families={families}
                              key={`${c.id}_${refreshKey}`}
                              sourceValue={c}
                              sourceID={source.id}
                            />
                          ))}
                        <Chip
                          label="New Color Value"
                          onClick={() => this.addSourceValue("colorTemp")}
                          avatar={
                            <Avatar style={{ background: "none" }}>
                              <Add />
                            </Avatar>
                          }
                          variant="outlined"
                        />
                      </TabContainer>,
                      <TabContainer key="grid">
                        {source &&
                          source.gridValues &&
                          source.gridValues.map((g) => (
                            <SourceValue
                              settings={settings}
                              deleteSourceValue={deleteSourceValue}
                              updateSourceValue={updateSourceValue}
                              refreshSource={refreshSource}
                              category="grid"
                              key={`${g.id}_${refreshKey}`}
                              families={families}
                              sourceValue={g}
                              sourceID={source.id}
                            />
                          ))}
                        <Chip
                          label="New Grid Value"
                          onClick={() => this.addSourceValue("grid")}
                          avatar={
                            <Avatar style={{ background: "none" }}>
                              <Add />
                            </Avatar>
                          }
                          variant="outlined"
                        />
                      </TabContainer>,
                    ]
                  : []}
                <TabContainer key="localizations">
                  {source &&
                    source.localizations &&
                    source.localizations.map((l) => (
                      <SourceLocalization
                        localization={l}
                        refreshSource={refreshSource}
                        deleteSourceLocalization={deleteSourceLocalization}
                        updateSourceLocalization={updateSourceLocalization}
                        languages={languages}
                        key={`localization_${l.id}_${refreshKey}`}
                        sourceID={source.id}
                      />
                    ))}
                  <Chip
                    label="New Localization"
                    onClick={async () => {
                      await createSourceLocalization({
                        sourceID: source.id,
                        languageID: languages[0].id,
                      });
                      refreshSource(source.id);
                    }}
                    avatar={
                      <Avatar style={{ background: "none" }}>
                        <Add />
                      </Avatar>
                    }
                    variant="outlined"
                  />
                </TabContainer>
              </SwipeableViews>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {source && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                &nbsp;
                {source ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withMobileDialog()(FormSource)
);
