import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

// component
import WrapperGelatinsPage from '../component/WrapperGelatinsPage';

const mapStateToProps = state => ({
  gelatins: state.gelatins,
  roles: state.roles,
});

const mapDispatchToProps = dispatch => ({
  getGelatins: () => dispatch(Actions.getGelatins()),
  getRoles: () => dispatch(Actions.getRoles()),
  getGelatinById: (...args) => dispatch(Actions.getGelatinById(...args)),
  createGelatin: (...args) => dispatch(Actions.createGelatin(...args)),
  deleteGelatin: (...args) => dispatch(Actions.deleteGelatin(...args)),
  updateGelatin: (...args) => dispatch(Actions.updateGelatin(...args)),
});
class GelatinPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,

    gelatins: PropTypes.array,
    getGelatins: PropTypes.func,
    getGelatinById: PropTypes.func,
    createGelatin: PropTypes.func,
    deleteGelatin: PropTypes.func,
    updateGelatin: PropTypes.func,

    getRoles: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, gelatins } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: gelatins.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getGelatins, getRoles } = this.props;
    await Promise.all([
      getGelatins(),
      getRoles(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      gelatins,
      roles,
      getGelatinById,
      createGelatin,
      deleteGelatin,
      updateGelatin,
      history,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperGelatinsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        gelatins={gelatins}
        roles={roles}
        getGelatinById={getGelatinById}
        createGelatin={createGelatin}
        deleteGelatin={deleteGelatin}
        updateGelatin={updateGelatin}
        urlParams={urlParams}
        history={history}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GelatinPage);
