import { networkAction } from 'helpers/network/networkAction';

import {
  GET_GELATINS,
  GET_GELATIN_BY_ID,
  DELETE_GELATIN,
  UPDATE_GELATIN,
  CREATE_GELATIN,
} from 'constants/gelatins';

import * as Api from 'api';

export const getGelatins = () => async dispatch => networkAction(
  dispatch,
  GET_GELATINS,
  Api.getGelatins,
  [],
);

export const getGelatinById = id => async dispatch => networkAction(
  dispatch,
  GET_GELATIN_BY_ID,
  Api.getGelatinById,
  [id],
);

export const deleteGelatin = id => async dispatch => networkAction(
  dispatch,
  DELETE_GELATIN,
  Api.deleteGelatin,
  [id],
);

export const createGelatin = body => async dispatch => networkAction(
  dispatch,
  CREATE_GELATIN,
  Api.createGelatin,
  [body],
);

export const updateGelatin = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_GELATIN,
  Api.updateGelatin,
  [id, body],
);
