import { SETTINGS } from 'constants/routes';
import { serviceRoles } from 'config/access';
import { checkAccess } from 'helpers/auth';
import SettingsPage from './container/SettingsPage';

export default function getSettingsRoute(userAuth) {
  const { predilux, account } = serviceRoles;
  const requireAuth = {
    [account.name]: [
      account.roles.superadmin,
    ],
    [predilux.name]: [
      predilux.roles.superadmin,
      predilux.roles.developer,
    ],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: SETTINGS,
    withSidebar: true,
    withAppBar: true,
    component: SettingsPage,
  };
}
