// material-ui
import { Icon, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// custom
import Table from "components/Table";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class SourcesList extends React.Component {
  static propTypes = {
    sources: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedSource) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedSource);
    }
  }

  render() {
    const { sources } = this.props;

    return (
      <Table
        data={sources}
        rowStyle={(d) => (d && !d.publish ? { opacity: 0.5 } : { opacity: 1 })}
        meta={[
          {
            path: "verified",
            render: (t, d) =>
              d.verified ? (
                <Tooltip
                  title={`Verified on ${moment(d.verificationDate)
                    .utc()
                    .format("l")}`}
                >
                  <Icon class="fas fa-badge-check" />
                </Tooltip>
              ) : (
                []
              ),
          },
          { path: "key", title: "Key", numeric: false },
          { path: "name", title: "Name", numeric: false },
          { path: "manufacturer", title: "Manufacturer", numeric: false },
          { path: "power", title: "Power", numeric: true },
          { path: "type", title: "Type", numeric: false },
        ]}
        title={"Sources"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(SourcesList);
