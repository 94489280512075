import {
  GET_SOURCES,
} from 'constants/sources';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_SOURCES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
