export default () => ({
  root: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
  },
});
