import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

// components
import RentalHousesList from 'components/RentalHousesList';
import Loading from 'components/Loading';
import FormRentalHouse from 'components/FormRentalHouse';

// styles
import styles from './styles';

class WrapperRentalHousePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //rentalHouses
    rentalHouses: PropTypes.array,
    getRentalHouseById: PropTypes.func,
    createRentalHouse: PropTypes.func,
    deleteRentalHouse: PropTypes.func,
    updateRentalHouse: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedRentalHouse: undefined,
      formRentalHouseOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { rentalHouses } = this.props;

    switch (index) {
      case 0:
        return (
          <RentalHousesList
            rentalHouses={rentalHouses}
            onSelect={(selectedRentalHouse) => this.setState({
              selectedRentalHouse,
              formRentalHouseOpen: true,
            })}
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createRentalHouse,
      deleteRentalHouse,
      updateRentalHouse,
      refresh,
    } = this.props;

    const {
      index,
      selectedRentalHouse,
      formRentalHouseOpen,
    } = this.state;

    return (
      <div className={classes.page}>
        {
          loading ?
          <Loading
            message={'Fetching all RentalHouses'}
          />
          :
          <div>
            <Helmet>
              <title>RentalHouses</title>
              <meta name="MyAccount" content="MyAccount page" />
            </Helmet>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push(`?index=${v}`);
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
            <div className={classes.container}>
              {
                this.getStepContent()
              }
            </div>
          </div>
        }
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formRentalHouseOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormRentalHouse
          open={formRentalHouseOpen}
          rentalHouse={selectedRentalHouse}
          close={() => {
            this.setState({
              formRentalHouseOpen: false,
            });
            setTimeout(() => this.setState({
              selectedRentalHouse: undefined,
            }), 200);
          }
          }
          createRentalHouse={createRentalHouse}
          deleteRentalHouse={deleteRentalHouse}
          updateRentalHouse={updateRentalHouse}
          refresh={refresh}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperRentalHousePage);
