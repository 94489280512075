import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'predilux';
const languages = `/${service}/languages`;

export function getLanguages() {
  const url = `${languages}`;
  return {
    method: GET,
    url,
  };
}

export function getLanguageById(id) {
  const url = `${languages}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateLanguage(id, body) {
  const url = `${languages}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLanguage(id) {
  const url = `${languages}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLanguage(body) {
  const url = `${languages}`;
  return {
    method: POST,
    url,
    body,
  };
}
