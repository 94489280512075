import { networkAction } from 'helpers/network/networkAction';

import {
  GET_SOURCES,
  GET_SOURCE_BY_ID,
  DELETE_SOURCE,
  UPDATE_SOURCE,
  CREATE_SOURCE,
} from 'constants/sources';

import * as Api from 'api';

export const getSources = () => async dispatch => networkAction(
  dispatch,
  GET_SOURCES,
  Api.getSources,
  [],
);

export const getSourceById = id => async dispatch => networkAction(
  dispatch,
  GET_SOURCE_BY_ID,
  Api.getSourceById,
  [id],
);

export const deleteSource = id => async dispatch => networkAction(
  dispatch,
  DELETE_SOURCE,
  Api.deleteSource,
  [id],
);

export const createSource = body => async dispatch => networkAction(
  dispatch,
  CREATE_SOURCE,
  Api.createSource,
  [body],
);

export const updateSource = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_SOURCE,
  Api.updateSource,
  [id, body],
);
