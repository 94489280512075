import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';

// components
import GelatinsList from 'components/GelatinsList';
import Loading from 'components/Loading';
import FormGelatin from 'components/FormGelatin';

// styles
import styles from './styles';

const indexDictinnary = {
  0: 'LEE Filters',
  1: 'Rosco',
  2: 'diff',
  3: 'cc',
  4: 'effect',
  5: 'nd',
  6: 'other',
  7: 'all',
};

class WrapperGelatinPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    gelatins: PropTypes.array,
    createGelatin: PropTypes.func,
    deleteGelatin: PropTypes.func,
    updateGelatin: PropTypes.func,

    // roles
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedGelatin: undefined,
      formGelatinOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { gelatins } = this.props;

    if (index <= 1) {
      console.log('brand');
      return (
        <GelatinsList
          gelatins={gelatins.filter(s => s.brand === indexDictinnary[index])}
          onSelect={selectedGelatin => this.setState({
            selectedGelatin,
            formGelatinOpen: true,
          })}
        />
      );
    }

    if ((index > 1) && (index < 7)) {
      console.log('type', indexDictinnary[index]);
      return (
        <GelatinsList
          gelatins={gelatins.filter(s => s.type === indexDictinnary[index])}
          onSelect={selectedGelatin => this.setState({
            selectedGelatin,
            formGelatinOpen: true,
          })}
        />
      );
    }

    console.log(index);

    if (index === 7) {
      return (
        <GelatinsList
          gelatins={gelatins.filter(s => (s.type === undefined) || (s.type === '') || (s.type === null))}
          onSelect={selectedGelatin => this.setState({
            selectedGelatin,
            formGelatinOpen: true,
          })}
        />
      );
    }

    if (index === 8) {
      return (
        <GelatinsList
          gelatins={gelatins.filter(s => (s.value === undefined) || (s.value === '') || (s.value === null))}
          onSelect={selectedGelatin => this.setState({
            selectedGelatin,
            formGelatinOpen: true,
          })}
        />
      );
    }

    return (
      <GelatinsList
        gelatins={gelatins}
        onSelect={selectedGelatin => this.setState({
          selectedGelatin,
          formGelatinOpen: true,
        })}
      />
    );
  }

  render() {
    const {
      classes,
      history,
      loading,
      createGelatin,
      deleteGelatin,
      updateGelatin,
      refresh,
      roles,
    } = this.props;

    const {
      index,
      selectedGelatin,
      formGelatinOpen,
    } = this.state;

    return (
      <div className={`${classes.background} page`}>
        {
          loading
            ? (
              <Loading
                message={'Fetching all Gelatins'}
              />
            )
            : (
              <div>
                <Helmet>
                  <title>Gelatins</title>
                  <meta name="MyAccount" content="MyAccount page" />
                </Helmet>
                <AppBar position="static" className={classes.appBar}>
                  <Tabs
                    value={index}
                    onChange={(e, v) => {
                      this.setState({
                        index: v,
                      });
                      history.push(`?index=${v}`);
                    }}

                    indicatorColor="primary"
                    textColor="primary"
                    scrollable
                    scrollButtons="auto"
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                  >
                    <Tab
                      label="LEE Filters"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="Rosco"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="diff"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="cc"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="effect"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="nd"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="other"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="undefined type"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="undefined value"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                    <Tab
                      label="all"
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                    />
                  </Tabs>
                </AppBar>
                <div className="container withAppBarAndTabs">
                  {
                    this.getStepContent()
                  }
                </div>
              </div>
            )
        }
        <Button
          variant="fab"
          color="primary"
          aria-label="Add"
          className={classes.floatingActionButton}
          onClick={() => this.setState({ formGelatinOpen: true })}
        >
          <AddIcon />
        </Button>
        <FormGelatin
          open={formGelatinOpen}
          gelatin={selectedGelatin}
          close={() => {
            this.setState({
              formGelatinOpen: false,
            });
            setTimeout(() => this.setState({
              selectedGelatin: undefined,
            }), 200);
          }
          }
          createGelatin={createGelatin}
          deleteGelatin={deleteGelatin}
          updateGelatin={updateGelatin}
          refresh={refresh}
          roles={roles}
        />
      </div>
    );
  }
}
export default withStyles(styles)(WrapperGelatinPage);
