import { networkAction } from 'helpers/network/networkAction';

import {
  GET_RENTAL_HOUSES,
  GET_RENTAL_HOUSE_BY_ID,
  DELETE_RENTAL_HOUSE,
  UPDATE_RENTAL_HOUSE,
  CREATE_RENTAL_HOUSE,
} from 'constants/rentalHouses';

import * as Api from 'api';

export const getRentalHouses = () => async dispatch => networkAction(
  dispatch,
  GET_RENTAL_HOUSES,
  Api.getRentalHouses,
  []
);

export const getRentalHouseById = (id) => async dispatch => networkAction(
  dispatch,
  GET_RENTAL_HOUSE_BY_ID,
  Api.getRentalHouseById,
  [id]
);

export const deleteRentalHouse = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_RENTAL_HOUSE,
  Api.deleteRentalHouse,
  [id]
);

export const createRentalHouse = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_RENTAL_HOUSE,
  Api.createRentalHouse,
  [body]
);

export const updateRentalHouse = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_RENTAL_HOUSE,
  Api.updateRentalHouse,
  [id, body]
);
