import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// component
import WrapperSigninPage from '../component/WrapperSigninPage';

// actions
import { signin } from 'actions/sessions';

const mapStateToProps = state => ({
  ...state,
});
const mapDispatchToProps = dispatch => ({
  signin: (...args) => dispatch(signin(...args)),
});
class SigninPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // api
    signin: PropTypes.func,

    // reducer
    session: PropTypes.object,
  };

  render() {
    const { history, location, signin, session } = this.props;
    return (
      <WrapperSigninPage
        history={history}
        location={location}
        signin={signin}
        session={session}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
