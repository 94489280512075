import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

// component
import WrapperSettingsPage from '../component/WrapperSettingsPage';

const mapStateToProps = state => ({
  settings: state.settings,
  roles: state.roles,
});

const mapDispatchToProps = dispatch => ({
  getSettings: () => dispatch(Actions.getSettings()),
  getRoles: () => dispatch(Actions.getRoles()),
  getSettingById: (...args) => dispatch(Actions.getSettingById(...args)),
  createSetting: (...args) => dispatch(Actions.createSetting(...args)),
  deleteSetting: (...args) => dispatch(Actions.deleteSetting(...args)),
  updateSetting: (...args) => dispatch(Actions.updateSetting(...args)),
});
class SettingPage extends React.Component {
  static propTypes = {
    location: PropTypes.object,

    settings: PropTypes.array,
    getSettings: PropTypes.func,
    getSettingById: PropTypes.func,
    createSetting: PropTypes.func,
    deleteSetting: PropTypes.func,
    updateSetting: PropTypes.func,

    getRoles: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, settings } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: settings.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getSettings, getRoles } = this.props;
    await Promise.all([
      getSettings(),
      getRoles(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      settings,
      roles,
      getSettingById,
      createSetting,
      deleteSetting,
      updateSetting,
      history,
    } = this.props;

    const {
      urlParams,
      loading,
    } = this.state;

    return (
      <WrapperSettingsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        settings={settings}
        roles={roles}
        getSettingById={getSettingById}
        createSetting={createSetting}
        deleteSetting={deleteSetting}
        updateSetting={updateSetting}
        history={history}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
