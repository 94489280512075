import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

// styles
import styles from './styles';

class TableGelatin extends Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    return (
      <Avatar style={{ background: datum.color }} />
    );
  }
}

export default withStyles(styles)(TableGelatin);
